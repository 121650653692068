// TradeTrigger.js

import { openDB } from 'idb';
import { fetchAsset, fetchPrices, updateAssetField, getAssets, writeTradeLog } from './db';
import { priceBounce, pivotBounce } from './PriceBounce';
//import { getTradingDetails } from './PlaceTrade.js';

//import { sendSMS } from './TwilioConnect';

// Exchange codes mapping for currencies
const currencyExchangeCodes = {
    "USD": "NYSE",
    "GBP": "LSE",
    "JPY": "TSE",
    "AUD": "ASX",
    "NZD": "NZSX",
    "CAD": "TSX",
    "CHF": "FWB",
    "EUR": "FWB",
    "SGD": "SGX",
    "NOK": "FWB"
    // Add other currency to exchange mappings as needed
};

// Exchange codes mapping for indices 
/*
const indexExchangeCodes = {
    "FTSE": "LSE",
    "DJI": "NYSE",
    "SPX": "NYSE",
    "NDX": "NYSE"
    // Add other index to exchange mappings as needed
};
*/
let stopPrice, riskRatio;
// Runs every minute to check for potential ready trades
export const tradeTrigger = async (assetSymbol = "") => {
    // Get all data 
    let asset, pricesMin, tradeDirection, mBounce, barEngulfing, currBar, prevBar, maxSize, targetPrice, prices1h, prices15min;
    console.log("Trade Trigger");

    const exchangeTimesMap = await preloadExchangeTimes();

    const assets = assetSymbol ? (typeof assetSymbol === 'string' ? [{ symbol: assetSymbol }] : assetSymbol) : await getAssets();
    for (const symbol of assets) {
        //console.log("Trade Trigger:", symbol);
        asset = await fetchAsset(symbol);
        stopPrice = 0;
        riskRatio = 0;
        tradeDirection = "";
        // Test for open markets
        // If at least one of teh currency exchanges is open for a Forex pair then trade is on as volume is sufficient
        // Indices are tradable from 6am to 9pm
        const assetType = asset.assettype;
        let tradeConditions = "CON"; // default setting   
        const isOpen = isExchangeOpenForAsset(exchangeTimesMap, symbol, assetType);
        await updateAssetField(symbol, "tradedirection", "");
        await updateAssetField(symbol, "stopprice", 0);
        await updateAssetField(symbol, "tradeconditions", "");
        console.log(isOpen, " exchanges open for ", symbol);
        if (isOpen > 0) {

            // test for volatility (using ATR) and Trading Conditions
            const averageAtr = asset.averagedailyatr;
            const atr = asset.dailyatr;
            const clearTrend = asset.cleartrend;

            // THIS PROCESS FOR VOLATILTIY IS FLAWED AND NEEDS FIXING - if no exchanges are open then do not trade Forex - just number open affect the average ATR?
            // NOT A GREAT DECISION - WORK OUT A BETTER ALGORITHM TO CALCULATE THIS
            // Volatility for Indices - always an exchange open, so test is just based on averageAtr
            // Volatility for Forex depends on the exchanges that are open 
            // Two Exchanges open means higher volume but also higher volatility - adjust atr test to reflect this

            let volatility = "N";
            if (isOpen === 1 && atr > averageAtr) {
                volatility = "Y";
            }
            if (isOpen === 2 && atr > averageAtr * 0.9) {
                volatility = "Y";
            }

            // Set Trade conditions based on volatility and trend



            if (volatility === "Y" && clearTrend === "N") {
                tradeConditions = "AGG";
            }
            if (volatility === "N" && clearTrend === "Y") {
                tradeConditions = "CON";
            }
            if (volatility === "Y" && clearTrend === "Y") {
                tradeConditions = "INT";
            }
            if (volatility === "N" && clearTrend === "N") {
                tradeConditions = "INT";
            }
            //console.log(tradeConditions, " trade Conditions for ", symbol);



            const tradePlayET = asset.readytradeet;
            const tradePlayLT = asset.readytradelt;
            const abovers = asset.abovers;
            const belowrs = asset.belowrs;
            const minutelyemabounce = asset.minutelyemabounce.slice(0, 1);
            const monthlyTrend = asset.monthlytrend;
            const weeklyTrend = asset.weeklytrend;

            pricesMin = await fetchPrices(symbol, "1min", true);

            if (tradePlayET) {
                //console.log("Easy Trader: ",tradePlayET," for ",symbol );
                const currDate = pricesMin[0].date;
                const currMinOpen = parseFloat(pricesMin[0].open);
                const currMinClose = parseFloat(pricesMin[0].close);
                const prevMinOpen = parseFloat(pricesMin[1].open);
                const prevMinClose = parseFloat(pricesMin[1].close);
                const ema10 = parseFloat(pricesMin[0].ema10);
                const ema20 = parseFloat(pricesMin[0].ema20);
                const ema50 = parseFloat(pricesMin[0].ema50);
                const ema100 = parseFloat(pricesMin[0].ema100);
                const ema200 = parseFloat(pricesMin[0].ema200);
                stopPrice = ema200; //default stop - conservative - best not to trade with this though
                await updateAssetField(symbol, "earlyadopter", "");
                await updateAssetField(symbol, "superearlyadopter", "");
                await updateAssetField(symbol, "earlyadopterpp", "");

                if (monthlyTrend === "bear" && weeklyTrend === "bear") {
                    tradeDirection = "bear";
                    targetPrice = belowrs;
                    await updateAssetField(symbol, "tradedirection", "bear");
                    //tradeType = "Reaction";
                }
                if (monthlyTrend === "bull" && weeklyTrend === "bull") {
                    tradeDirection = "bull";
                    targetPrice = abovers;
                    await updateAssetField(symbol, "tradedirection", "bull");
                    //tradeType = "Reaction";
                }


                //console.log("Trade ", tradePlayET, " ema's are: ", ema10, ema20, ema50, ema100, ema200);

                let stopLoc = ema200; // default setting

                if (tradeConditions === "AGG") {
                    stopLoc = ema50;
                }
                if (tradeConditions === "INT") {
                    stopLoc = ema100;
                }
                if (tradeConditions === "CON") {
                    stopLoc = ema200;
                }
                maxSize = 0;  // Placeholder

                // **********************************************************************************************
                // Super Early Adopter BULL
                if (currMinClose > ema10 && ema10 > ema20 && ema20 > ema50 && ema20 > ema100 && ema20 > ema200 && tradeConditions === "AGG" && tradeDirection === "bull") {
                    // GO TRADE    
                    ({ stopPrice, riskRatio } = riskCalculations(tradeDirection, currMinClose, stopLoc, targetPrice));
                    await updateAssetField(symbol, "superearlyadopter", tradeDirection);
                    await writeTradeLog(currDate, symbol, tradePlayET, tradeDirection, "super early", currMinClose, stopPrice, tradeConditions, riskRatio, maxSize);
                }

                // **********************************************************************************************
                // Super Early Adopter BEAR
                if (currMinClose < ema10 && ema10 < ema20 && ema20 < ema50 && ema20 < ema100 && ema20 < ema200 && tradeConditions === "AGG" && tradeDirection === "bear") {
                    // GO TRADE               
                    ({ stopPrice, riskRatio } = riskCalculations(tradeDirection, currMinClose, stopLoc, targetPrice));
                    await updateAssetField(symbol, "superearlyadopter", tradeDirection);
                    await writeTradeLog(currDate, symbol, tradePlayET, tradeDirection, "super early", currMinClose, stopPrice, tradeConditions, riskRatio, maxSize);
                }

                // **********************************************************************************************
                // Early Adopter


                if (currMinOpen < currMinClose) {
                    currBar = "bull";
                } else {
                    currBar = "bear";
                }
                if (prevMinOpen < prevMinClose) {
                    prevBar = "bull";
                } else {
                    prevBar = "bear";
                }



                // Bull Engulfing bar
                barEngulfing = "";
                if (currMinClose > prevMinOpen && currBar === "bull" && prevBar === "bear") {
                    barEngulfing = "bull";
                }

                // Bear engulfing bar

                if (currMinClose < prevMinOpen && currBar === "bear" && prevBar === "bull") {
                    barEngulfing = "bear";
                }


                // CONSOLE OUTPUT FOR ERROR CHECK ***********************************************************************

                //console.log("Engulfing Bar ", barEngulfing);
                //console.log("currBar ", currBar);
                //console.log("prevBar ", prevBar);
                //console.log("tradeDirection", tradeDirection, "tradePlayET", tradePlayET);
                //console.log("Date ", currDate, "Open ", currMinOpen, "Close ", currMinClose, "High ", currMinHigh, "Low ", currMinLow);
                //console.log("ema10 ", ema10, "ema20", ema20, "ema50", ema50, "ema100", ema100, "ema200", ema200);

                // BULL 
                if (ema50 > ema100 && ema50 > ema200) {
                    if (tradeDirection === "bull" && barEngulfing === "bull") {
                        // ema50 pullback
                        mBounce = await priceBounce(pricesMin, 'ema50');
                        if (mBounce === tradeDirection) {
                            console.log("ema50 pullback ", tradeDirection, currMinClose, ema50, targetPrice);
                            let { stopPrice, riskRatio } = riskCalculations(tradeDirection, currMinClose, ema50, targetPrice);
                            await updateAssetField(symbol, "earlyadopter", tradeDirection + " 50");
                            await writeTradeLog(currDate, symbol, tradePlayET, tradeDirection + " 50", "early", currMinClose, stopPrice, tradeConditions, riskRatio, maxSize);
                        }
                        // ema100 pullback
                        if (mBounce === "") {
                            mBounce = await priceBounce(pricesMin, 'ema100');
                            if (mBounce === tradeDirection) {
                                console.log("ema100 pullback ", tradeDirection, currMinClose, ema100, targetPrice);
                                let { stopPrice, riskRatio } = riskCalculations(tradeDirection, currMinClose, ema100, targetPrice);
                                await updateAssetField(symbol, "earlyadopter", tradeDirection + " 100");
                                await writeTradeLog(currDate, symbol, tradePlayET, tradeDirection + " 100", "early", currMinClose, stopPrice, tradeConditions, riskRatio, maxSize);
                            }
                        }

                        // ema200 pullback
                        if (mBounce === "") {
                            mBounce = await priceBounce(pricesMin, 'ema200');
                            if (mBounce === tradeDirection) {
                                console.log("ema200 pullback ", tradeDirection, currMinClose, ema200, targetPrice);
                                let { stopPrice, riskRatio } = riskCalculations(tradeDirection, currMinClose, ema200, targetPrice);
                                await updateAssetField(symbol, "earlyadopter", tradeDirection + " 200");
                                await writeTradeLog(currDate, symbol, tradePlayET, tradeDirection + " 200", "early", currMinClose, stopPrice, tradeConditions, riskRatio, maxSize);
                            }
                        }

                        // Pivot Point pullback
                        if (mBounce === "") {
                            let { mBounce, suppLevel } = await pivotBounce(pricesMin, 'dailypivot', symbol);
                            if (mBounce === tradeDirection) {
                                console.log("Pivot Point pullback ", tradeDirection, currMinClose, suppLevel, targetPrice);
                                let { stopPrice, riskRatio } = riskCalculations(tradeDirection, currMinClose, suppLevel, targetPrice);
                                await updateAssetField(symbol, "earlyadopterpp", tradeDirection + " PP");
                                await writeTradeLog(currDate, symbol, tradePlayET, tradeDirection + " pp", "early", currMinClose, stopPrice, tradeConditions, riskRatio, maxSize);
                            }
                        }
                        // S1 pullback
                        if (mBounce === "") {
                            let { mBounce, suppLevel } = await pivotBounce(pricesMin, 'dailypivots1', symbol);
                            if (mBounce === tradeDirection) {
                                console.log("S1 pullback ", tradeDirection, currMinClose, suppLevel, targetPrice);
                                let { stopPrice, riskRatio } = riskCalculations(tradeDirection, currMinClose, suppLevel, targetPrice);
                                await updateAssetField(symbol, "earlyadopterpp", tradeDirection + " S1");
                                await writeTradeLog(currDate, symbol, tradePlayET, tradeDirection + " S1", "early", currMinClose, stopPrice, tradeConditions, riskRatio, maxSize);
                            }
                        }
                        // S2 pullback
                        if (mBounce === "") {
                            let { mBounce, suppLevel } = await pivotBounce(pricesMin, 'dailypivots2', symbol);
                            if (mBounce === tradeDirection) {
                                console.log("S2 pullback ", tradeDirection, currMinClose, suppLevel, targetPrice);
                                let { stopPrice, riskRatio } = riskCalculations(tradeDirection, currMinClose, suppLevel, targetPrice);
                                await updateAssetField(symbol, "earlyadopterpp", tradeDirection + " S2");
                                await writeTradeLog(currDate, symbol, tradePlayET, tradeDirection + " S2", "early", currMinClose, stopPrice, tradeConditions, riskRatio, maxSize);
                            }

                        }
                        // R1 pullback
                        if (mBounce === "") {
                            let { mBounce, suppLevel } = await pivotBounce(pricesMin, 'dailypivotr1', symbol);
                            if (mBounce === tradeDirection) {
                                console.log("R1 pullback ", tradeDirection, currMinClose, suppLevel, targetPrice);
                                let { stopPrice, riskRatio } = riskCalculations(tradeDirection, currMinClose, suppLevel, targetPrice);
                                await updateAssetField(symbol, "earlyadopterpp", tradeDirection + " R1");
                                await writeTradeLog(currDate, symbol, tradePlayET, tradeDirection + " R1", "early", currMinClose, stopPrice, tradeConditions, riskRatio, maxSize);
                            }
                        }
                        // R2 pullback
                        if (mBounce === "") {
                            let { mBounce, suppLevel } = await pivotBounce(pricesMin, 'dailypivotr2', symbol);
                            if (mBounce === tradeDirection) {
                                console.log("R2 pullback ", tradeDirection, currMinClose, suppLevel, targetPrice);
                                let { stopPrice, riskRatio } = riskCalculations(tradeDirection, currMinClose, suppLevel, targetPrice);
                                await updateAssetField(symbol, "earlyadopterpp", tradeDirection + " R2");
                                await writeTradeLog(currDate, symbol, tradePlayET, tradeDirection + " R2", "early", currMinClose, stopPrice, tradeConditions, riskRatio, maxSize);
                            }
                        }
                        //let { availableFunds, marginRequirement, spread, minStopDistance } = await getTradingDetails(symbol);
                        //console.log(availableFunds, marginRequirement, spread, minStopDistance);
                    }

                    // EMA 10/20 Bounce
                    if (minutelyemabounce === "Y" && tradeDirection === "bull" && currMinClose > ema10) {
                        console.log("EMA 10/20 Bounce ", tradeDirection, currMinClose, ema10, ema20, targetPrice);
                        ({ stopPrice, riskRatio } = riskCalculations(tradeDirection, currMinClose, stopLoc, targetPrice));
                        await updateAssetField(symbol, "earlyadopter", tradeDirection + " 10/20");
                        await writeTradeLog(currDate, symbol, tradePlayET, tradeDirection + " 10/20", "early", currMinClose, stopPrice, tradeConditions, riskRatio, maxSize);

                    }
                }

                // BEAR
                if (ema50 < ema100 && ema50 < ema200) {
                    if (tradeDirection === "bear" && barEngulfing === "bear") {
                        // ema50 pullback
                        mBounce = await priceBounce(pricesMin, 'ema50');
                        if (mBounce === tradeDirection) {
                            console.log("ema50 pullback ", tradeDirection, currMinClose, ema50, targetPrice);
                            let { stopPrice, riskRatio } = riskCalculations(tradeDirection, currMinClose, ema50, targetPrice);
                            await updateAssetField(symbol, "earlyadopter", tradeDirection + " 50");
                            await writeTradeLog(currDate, symbol, tradePlayET, tradeDirection + " 50", "early", currMinClose, stopPrice, tradeConditions, riskRatio, maxSize);
                        }
                        // ema100 pullback
                        if (mBounce === "") {
                            mBounce = await priceBounce(pricesMin, 'ema100');
                            if (mBounce === tradeDirection) {
                                console.log("ema100 pullback ", tradeDirection, currMinClose, ema100, targetPrice);
                                let { stopPrice, riskRatio } = riskCalculations(tradeDirection, currMinClose, ema100, targetPrice);
                                await updateAssetField(symbol, "earlyadopter", tradeDirection + " 100");
                                await writeTradeLog(currDate, symbol, tradePlayET, tradeDirection + " 100", "early", currMinClose, stopPrice, tradeConditions, riskRatio, maxSize);
                            }
                        }

                        // ema200 pullback
                        if (mBounce === "") {
                            mBounce = await priceBounce(pricesMin, 'ema200');
                            if (mBounce === tradeDirection) {
                                console.log("ema200 pullback ", tradeDirection, currMinClose, ema200, targetPrice);
                                let { stopPrice, riskRatio } = riskCalculations(tradeDirection, currMinClose, ema200, targetPrice);
                                await updateAssetField(symbol, "earlyadopter", tradeDirection + " 200");
                                await writeTradeLog(currDate, symbol, tradePlayET, tradeDirection + " 200", "early", currMinClose, stopPrice, tradeConditions, riskRatio, maxSize);
                            }
                        }

                        // Pivot Point pullback
                        if (mBounce === "") {
                            let { mBounce, suppLevel } = await pivotBounce(pricesMin, 'dailypivot', symbol);
                            if (mBounce === tradeDirection) {
                                console.log("Pivot Point pullback ", tradeDirection, currMinClose, suppLevel, targetPrice);
                                let { stopPrice, riskRatio } = riskCalculations(tradeDirection, currMinClose, suppLevel, targetPrice);
                                await updateAssetField(symbol, "earlyadopterpp", tradeDirection + " PP");
                                await writeTradeLog(currDate, symbol, tradePlayET, tradeDirection + " pp", "early", currMinClose, stopPrice, tradeConditions, riskRatio, maxSize);
                            }
                        }
                        // S1 pullback
                        if (mBounce === "") {
                            let { mBounce, suppLevel } = await pivotBounce(pricesMin, 'dailypivots1', symbol);
                            if (mBounce === tradeDirection) {
                                console.log("S1 pullback ", tradeDirection, currMinClose, suppLevel, targetPrice);
                                let { stopPrice, riskRatio } = riskCalculations(tradeDirection, currMinClose, suppLevel, targetPrice);
                                await updateAssetField(symbol, "earlyadopterpp", tradeDirection + " S1");
                                await writeTradeLog(currDate, symbol, tradePlayET, tradeDirection + " S1", "early", currMinClose, stopPrice, tradeConditions, riskRatio, maxSize);
                            }
                        }
                        // S2 pullback
                        if (mBounce === "") {
                            let { mBounce, suppLevel } = await pivotBounce(pricesMin, 'dailypivots2', symbol);
                            if (mBounce === tradeDirection) {
                                console.log("S2 pullback ", tradeDirection, currMinClose, suppLevel, targetPrice);
                                let { stopPrice, riskRatio } = riskCalculations(tradeDirection, currMinClose, suppLevel, targetPrice);
                                await updateAssetField(symbol, "earlyadopterpp", tradeDirection + " S2");
                                await writeTradeLog(currDate, symbol, tradePlayET, tradeDirection + " S2", "early", currMinClose, stopPrice, tradeConditions, riskRatio, maxSize);
                            }

                        }
                        // R1 pullback
                        if (mBounce === "") {
                            let { mBounce, suppLevel } = await pivotBounce(pricesMin, 'dailypivotr1', symbol);
                            if (mBounce === tradeDirection) {
                                console.log("R1 pullback ", tradeDirection, currMinClose, suppLevel, targetPrice);
                                let { stopPrice, riskRatio } = riskCalculations(tradeDirection, currMinClose, suppLevel, targetPrice);
                                await updateAssetField(symbol, "earlyadopterpp", tradeDirection + " R1");
                                await writeTradeLog(currDate, symbol, tradePlayET, tradeDirection + " R1", "early", currMinClose, stopPrice, tradeConditions, riskRatio, maxSize);
                            }
                        }
                        // R2 pullback
                        if (mBounce === "") {
                            let { mBounce, suppLevel } = await pivotBounce(pricesMin, 'dailypivotr2', symbol);
                            if (mBounce === tradeDirection) {
                                console.log("R2 pullback ", tradeDirection, currMinClose, suppLevel, targetPrice);
                                let { stopPrice, riskRatio } = riskCalculations(tradeDirection, currMinClose, suppLevel, targetPrice);
                                await updateAssetField(symbol, "earlyadopterpp", tradeDirection + " R2");
                                await writeTradeLog(currDate, symbol, tradePlayET, tradeDirection + " R2", "early", currMinClose, stopPrice, tradeConditions, riskRatio, maxSize);
                            }
                        }
                        //let { availableFunds, marginRequirement, spread, minStopDistance } = await getTradingDetails(symbol);
                        //console.log(availableFunds, marginRequirement, spread, minStopDistance);
                    }

                    // EMA 10/20 Bounce
                    if (minutelyemabounce === "N" && tradeDirection === "bear" && currMinClose < ema10) {
                        console.log("EMA 10/20 Bounce ", tradeDirection, currMinClose, ema10, ema20, targetPrice);
                        ({ stopPrice, riskRatio } = riskCalculations(tradeDirection, currMinClose, stopLoc, targetPrice));
                        await updateAssetField(symbol, "earlyadopter", tradeDirection + " 10/20");
                        await writeTradeLog(currDate, symbol, tradePlayET, tradeDirection + " 10/20", "early", currMinClose, stopPrice, tradeConditions, riskRatio, maxSize);

                    }
                }

            }

            // ***** LAZY TRADER PLAY ****************************************************************

            if (tradePlayLT) {
                let stackGood = false;
                if (tradePlayLT.slice(0, 4) === "bear") {
                    tradeDirection = "bear";
                    targetPrice = belowrs;
                    await updateAssetField(symbol, "tradedirection", "bear");
                }
                if (tradePlayLT.slice(0, 4) === "bull") {
                    tradeDirection = "bull";
                    targetPrice = abovers;
                    await updateAssetField(symbol, "tradedirection", "bull");
                }

                prices1h = await fetchPrices(symbol, "1h", true);
                prices15min = await fetchPrices(symbol, "15min", true);

                const hourlyemabounce = parseFloat(asset.hourlyemabounce);
                const hourlyema10 = parseFloat(prices1h[0].ema10);
                const hourlyema20 = parseFloat(prices1h[0].ema20);
                const hourlyema50 = parseFloat(prices1h[0].ema50);
                const hourlyema200 = parseFloat(prices1h[0].ema200);
                const fifteenminutelyemabounce = parseFloat(asset.fifteenminutelyemabounce);
                const fifteenminutelyema10 = parseFloat(prices15min[0].ema10);
                const fifteenminutelyema20 = parseFloat(prices15min[0].ema20);
                const fifteenminutelyema200 = parseFloat(prices15min[0].ema200);
                const minutelyemabounce = parseFloat(asset.minutelyemabounce);
                const minutelyema10 = parseFloat(pricesMin[0].ema10);
                const minutelyema20 = parseFloat(pricesMin[0].ema20);
                const minutelyema200 = parseFloat(pricesMin[0].ema200);
                const currDate = pricesMin[0].date;
                const currMinClose = parseFloat(pricesMin[0].close);

                const timeFrame = tradePlayLT.slice(-2);
                let playFrame;

                switch (timeFrame) {
                    case '1D':
                        playFrame = '1h';
                        if ((currMinClose > hourlyema10 && hourlyema10 > hourlyema20 && hourlyema20 > hourlyema200 && hourlyemabounce === "Y" && tradeDirection === "bull") ||
                            (currMinClose < hourlyema10 && hourlyema10 < hourlyema20 && hourlyema20 < hourlyema200 && hourlyemabounce === "N" && tradeDirection === "bear")) {
                            stackGood = true;
                        }
                        //console.log("hourlyema10 ",hourlyema10, "hourlyema20 ", hourlyema20, "hourlyema200 ",hourlyema200, "tradeDirection ",tradeDirection, "stackGood ", stackGood);
                        break;
                    case '4h':
                        playFrame = '15min';
                        if ((currMinClose > fifteenminutelyema10 && fifteenminutelyema10 > fifteenminutelyema20 && fifteenminutelyema20 > fifteenminutelyema200 && fifteenminutelyemabounce === "Y" && tradeDirection === "bull") ||
                            (currMinClose < fifteenminutelyema10 && fifteenminutelyema10 < fifteenminutelyema20 && fifteenminutelyema20 < fifteenminutelyema200 && fifteenminutelyemabounce === "N" && tradeDirection === "bear")) {
                            stackGood = true;
                        }
                        break;
                    case '1h':
                        playFrame = '1min';
                        if ((currMinClose > minutelyema10 && minutelyema10 > minutelyema20 && minutelyema20 > minutelyema200 && minutelyemabounce === "Y" && tradeDirection === "bull") ||
                            (currMinClose < minutelyema10 && minutelyema10 < minutelyema20 && minutelyema20 < minutelyema200 && minutelyemabounce === "N" && tradeDirection === "bear")) {
                            stackGood = true;
                        }
                        break;
                    default:
                        playFrame = '';
                        break;
                }

                if (stackGood === true) {
                    //console.log("time frame: ", timeFrame, "playFrame ", playFrame);

                    // DAILY EMA BOUNCE ********************************************************************
                    // 1 hour play
                    if (playFrame === "1h") {                        
                            console.log("DAILY EMA BOUNCE 1hr ");
                            let { stopPrice, riskRatio } = riskCalculations(tradeDirection, currMinClose, hourlyema50, targetPrice);
                            await updateAssetField(symbol, "lazytraderema", tradeDirection + " 10/20 1h");
                            await writeTradeLog(currDate, symbol, tradePlayLT, tradeDirection + " 10/20 1h", "lazy", currMinClose, stopPrice, tradeConditions, riskRatio, maxSize);
                    }

                    // 4 HOUR EMA BOUNCE ********************************************************************
                    // 15 minute play
                    if (playFrame === "15min") {                      
                            console.log("4 HOUR EMA BOUNCE 15min ");
                            let { stopPrice, riskRatio } = riskCalculations(tradeDirection, currMinClose, hourlyema20, targetPrice);
                            await updateAssetField(symbol, "lazytraderema", tradeDirection + " 10/20 15min");
                            await writeTradeLog(currDate, symbol, tradePlayLT, tradeDirection + " 10/20 15min", "lazy", currMinClose, stopPrice, tradeConditions, riskRatio, maxSize);
                    }


                    // 15 MINUTE EMA BOUNCE ********************************************************************
                    // 1 minute play
                    if (playFrame === "1min") {                        
                            console.log("15 MINUTE EMA BOUNCE 1m ");
                            let { stopPrice, riskRatio } = riskCalculations(tradeDirection, currMinClose, fifteenminutelyema20, targetPrice);
                            await updateAssetField(symbol, "lazytraderema", tradeDirection + " 10/20 1min");
                            await writeTradeLog(currDate, symbol, tradePlayLT, tradeDirection + " 10/20 1min", "lazy", currMinClose, stopPrice, tradeConditions, riskRatio, maxSize);
                    }
                }
            }
            await updateAssetField(symbol, "stopprice", stopPrice);
            await updateAssetField(symbol, "tradeconditions", tradeConditions);
        }
    }
}

//******************************************************************************************** */
// Function to preload all exchange times
const preloadExchangeTimes = async () => {
    try {
        const db = await openDB('AssetsDatabase');
        const transaction = db.transaction('exchangetimes', 'readonly');
        const store = transaction.objectStore('exchangetimes');
        const allExchangeTimes = await store.getAll();
        db.close();

        const exchangeTimesMap = {};
        allExchangeTimes.forEach(exchangeTime => {
            exchangeTimesMap[exchangeTime.code] = exchangeTime;
        });

        return exchangeTimesMap;
    } catch (error) {
        console.error('Error preloading exchange times:', error);
        return {};
    }
};

// Helper function to get current half-hour time slot
const getCurrentHalfHourTimeSlot = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes() < 30 ? '00' : '30';
    return `${hours}:${minutes}`;
};

//******************************************************************************************************************* */
// Function to check if exchange is open for a given asset
const isExchangeOpenForAsset = (exchangeTimesMap, assetSymbol, assetType) => {
    const now = new Date();
    const dayOfWeek = now.getDay();
    // Check if today is Saturday (6) or Sunday (0)
    if (dayOfWeek === 0 || dayOfWeek === 6) {
        return 0; // Exchange is closed on weekends
    }
    const currentTimeSlot = getCurrentHalfHourTimeSlot();
    let openExchanges = 0;

    if (assetType === 'I' || assetType === 'C' || assetType === 'S') {
        // For indices if any major exchange is open then it is tradable

        //const exchangeCode = indexExchangeCodes[assetSymbol];
        //if (exchangeCode && exchangeTimesMap[exchangeCode][currentTimeSlot] === 'open') {
        openExchanges = 1;
        //}
    } else if (assetType === 'F') {
        // For Forex pairs
        const openExchangeSet = new Set();
        const currencies = assetSymbol.split('/');
        currencies.forEach(currency => {
            const exchangeCode = currencyExchangeCodes[currency];
            //console.log("exchangeCode ",exchangeCode);
            // added to a set and set size measured at the end in case both have the same exchange which only counts as one
            if (exchangeCode && exchangeTimesMap[exchangeCode][currentTimeSlot] === 'open') {
                openExchangeSet.add(exchangeCode);
            }
        });
        openExchanges = openExchangeSet.size;
    }
    return openExchanges;
};

//********************************************************************************************** */
function riskCalculations(tradeDirection, currMinClose, suppPrice, targetPrice) {
    //console.log("risk calculations ", tradeDirection, currMinClose, suppPrice, targetPrice);
    let stopPrice;
    let stopDistance = Math.abs(suppPrice - currMinClose) * 1.2;
    let riskRatio = Math.abs(targetPrice - currMinClose) / stopDistance;

    // Convert riskRatio to a string with 1 decimal place
    riskRatio = parseFloat(riskRatio.toFixed(1));

    // Stop Price
    if (tradeDirection === "bull") {
        stopPrice = currMinClose - stopDistance;
    } else {
        stopPrice = currMinClose + stopDistance;
    }

    // Convert stopPrice to a string with 6 significant figures
    stopPrice = parseFloat(stopPrice.toPrecision(6));
    //console.log("Risk: ", tradeDirection, currMinClose, suppPrice, targetPrice, stopPrice, riskRatio);
    return { stopPrice, riskRatio };
}
