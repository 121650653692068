// UpdateIndicators.js

import { getAssets, getCurrentVersion, updateAssetField, sixSigFig } from './db';
import { openDB } from 'idb';

const defaultIntervals = ["1month", "1week", "1day", "4h", "1h", "15min", "1min"];

//**************************************************************************************** */
export async function updateIndicators (assetSymbol = "", intervals = "") {
  console.log('updateIndicators called with symbols:', assetSymbol, 'and intervals:', intervals);

  // Determine the assets and intervals to use based on the parameters
  const assets = assetSymbol ? (typeof assetSymbol === 'string' ? [{ symbol: assetSymbol }] : assetSymbol) : await getAssets();
  const effectiveIntervals = intervals && intervals.length ? intervals : defaultIntervals;

  const currentVersion = await getCurrentVersion();

  const db = await openDB('AssetsDatabase', currentVersion);
  db.onerror = (event) => {
    console.error("Database error:", event.target.errorCode);
  };

   try {
    const allPromises = assets.flatMap(symbol => 
      effectiveIntervals.map(interval => processInterval(db, symbol, interval))
    );

    // Await all promises to resolve before closing the database
    await Promise.all(allPromises);
  } catch (error) {
    console.error('Error processing one or more intervals:', error);
  } 
  db.close(); 
  
};

//**************************************************************************************** */
async function processInterval(db, symbol, interval) {
  const tableName = `${symbol.replace('/', '_')}-${interval}`;
  let transaction = db.transaction([tableName], "readwrite");
  let objectStore = transaction.objectStore(tableName);
  let index = objectStore.index('date');

  let data = [];
  let cursor = await index.openCursor(null, 'next');
  while (cursor) {
    data.push(cursor.value);
    cursor = await cursor.continue();
  }

  // Now all entries have been processed
  const updatedData = computeIndicators(data, interval, symbol);
  for (const item of updatedData) { // Use for...of for synchronous iteration over async operations
    let updateRequest = objectStore.put(item);
    // You can still use onerror to log update errors
    updateRequest.onerror = event => {
      console.error('Error updating data:', event.target.errorCode);
      // Throw an error to reject the promise returned by async function
      throw new Error(`Error updating data for ${item.date}: ${event.target.errorCode}`);
    };
  }

  // Await transaction completion using a promise
  await new Promise((resolve, reject) => {
    transaction.oncomplete = resolve;
    transaction.onerror = () => reject(new Error("Transaction failed"));
  });
}


//**************************************************************************************** */
function computeIndicators(data, interval, symbol) {
  const emaPeriods = [10, 20, 50, 100, 200];
  const atrPeriod = 14;

  for (const period of emaPeriods) {
    const emaKey = `ema${period}`;
    const emaValues = calculateEMA(data, period);
    for (let i = 0; i < data.length; i++) {
      data[i][emaKey] = emaValues[i];
    }
  }

  // Only calculate ATR for 1 day and 4h data
  if (interval === "4h") {
    const atrValues = calculateATR(data, atrPeriod);
    for (let i = 0; i < data.length; i++) {
      data[i].atr = atrValues[i];  // Add the ATR value to each data item
    }
  }

  if (interval === "1day") {
    const atrValues = calculateATR(data, atrPeriod);
    let atrSum = 0;
    let atrCount = 0;

    for (let i = 0; i < data.length; i++) {
      data[i].atr = atrValues[i];  // Add the ATR value to each data item
      if (atrValues[i] !== "") {   // Ensure only non-empty values are considered for averaging
        atrSum += parseFloat(atrValues[i]);
        atrCount++;
      }
    }
    if (atrCount > 0) {
      const averageAtr = atrSum / atrCount;
      updateAssetField(symbol, 'averagedailyatr', sixSigFig(averageAtr, 6));
    }
  }

  return data;
}

//**************************************************************************************** */
function calculateEMA(prices, period) {

  if (prices.length === 0) {
    console.error("No price data available for EMA calc");
    return [];
  }

  const alpha = 2 / (period + 1);
  let prevEma = parseFloat(prices[0].close);
  const emaValues = [prevEma];  // Starting EMA value is the close price of the first period

  for (let i = 1; i < prices.length; i++) {
    const currentClose = parseFloat(prices[i].close);
    const ema = ((currentClose - prevEma) * alpha) + prevEma;
    emaValues.push(ema.toFixed(6));
    prevEma = ema;
  }
  return emaValues;
}

//**************************************************************************************** */
function calculateTrueRange(currentBar, prevBar) {
  const currentHigh = parseFloat(currentBar.high);
  const currentLow = parseFloat(currentBar.low);
  const prevClose = parseFloat(prevBar.close);

  const trueRange = Math.max(
    currentHigh - currentLow,
    Math.abs(currentHigh - prevClose),
    Math.abs(currentLow - prevClose)
  );
  return trueRange;
}

//**************************************************************************************** */
function calculateATR(data, period) {

  if (data.length === 0) {
    console.error("No data available for ATR calc");
    return [];
  }
  if (data.length < period) {
    console.error("Too little data < 14");
    return [];
  }

  const atrValues = [];

  for (let i = 0; i < data.length; i++) {
    if (i < period - 1) {
      atrValues.push("");
    } else {
      let trueRangeSum = 0;
      for (let j = i - period + 1; j <= i; j++) {
        if (data[j] && data[j - 1]) {
          trueRangeSum += calculateTrueRange(data[j], data[j - 1]);
        }
      }
      atrValues.push(((trueRangeSum / period).toFixed(6)));
    }
  }
  return atrValues;
}
