// ForexData.js

import React, { useState, useEffect, useCallback } from 'react';
import { openDB } from 'idb';

const TodaysForex = () => {
    const [highImpactData, setHighImpactData] = useState([]);

    const fetchHighImpactForexData = useCallback(async () => {
        try {
            const today = new Date().toISOString().slice(0, 10);
            const db = await openDB('AssetsDatabase');
            const tx = db.transaction('forexdates', 'readonly');
            const store = tx.objectStore('forexdates');
            const index = store.index('dateAndImpact');

            const range = IDBKeyRange.lowerBound([today, '']);
            const highImpactRecords = await index.getAll(range);
            await tx.done;
            setHighImpactData(highImpactRecords.filter(record => record.impact === 'High'));
            db.close();
        } catch (error) {
            console.error('Error fetching high impact data:', error);
        }
    }, []); // Dependencies array is empty, so this function is memoized until the component unmounts

    const initializeAndFetchForexData = useCallback(async () => {
        console.log("opening Todays forex");
        await fetchHighImpactForexData();
        console.log("closing Todays forex");
    }, [fetchHighImpactForexData]); // Dependencies array includes fetchHighImpactForexData

    useEffect(() => {
        initializeAndFetchForexData();
    }, [initializeAndFetchForexData]); // Include initializeAndFetchForexData in the dependencies array


    return (
        <div>
            <h4>Weekly Forex</h4>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Title</th>
                        <th>Impact</th>
                        <th>Currency</th>
                    </tr>
                </thead>
                <tbody>
                    {highImpactData.map((record, index) => (
                        <tr key={index}>
                            <td>{record.date}</td>
                            <td>{record.time}</td>
                            <td>{record.title}</td>
                            <td>{record.impact}</td>
                            <td>{record.country}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TodaysForex;
