// AnalyseMarkets.js

import { openDB } from 'idb';
import { getAssets, getCurrentVersion, updateAssetField, fetchAsset, sixSigFig } from './db.js';
import { priceBounce } from './PriceBounce';


const defaultIntervals = ["1month", "1week", "1day", "4h", "1h", "15min", "1min"];

export async function analyseMarket (assetSymbol = "", intervals = "", endDate = "")  {
    console.log('analyseMarket called with symbols:', assetSymbol, 'and intervals:', intervals, 'and endDate:', endDate);

    // Determine the assets and intervals to use based on the parameters
    const assets = assetSymbol ? (typeof assetSymbol === 'string' ? [{ symbol: assetSymbol }] : assetSymbol) : await getAssets();
    const effectiveIntervals = intervals && intervals.length ? intervals : defaultIntervals;

    const intervalCodes = {
        "1month": "monthly",
        "1week": "weekly",
        "1day": "daily",
        "4h": "fourhourly",
        "1h": "hourly",
        "15min": "fifteenminutely",
        "1min": "minutely"
    };

    let emaStack = "", bounce = "", bounce200 = "", bounce100 = "", bounce50 = "", processdate, PivotPoint, S1, S2, R1, R2, latestema200, latestclose, latestatr, latestData, nextData, lastMajor, lastMinor;
    for (const symbol of assets) {

        for (const interval of effectiveIntervals) {
            const tableName = `${symbol.replace('/', '_')}-${interval}`;
            try {
                const data = await fetchData(tableName); 

                if (data && data.length > 0) {
                    // Get the latest data entry which should have the most recent date and EMA values.
                    latestData = data[0];
                    const { date, open, close, low, high, atr, ema10, ema20, ema50, ema100, ema200, major, minor } = latestData;
                     latestclose = sixSigFig(close, 6);
                     processdate = date;
                    if (interval === "1day" || interval === "4h") {
                        // PIVOT POINT PROCESSING **********************************************************************************
                        PivotPoint = sixSigFig((high + low + close) / 3, 6);
                        S1 = sixSigFig(((PivotPoint * 2) - high), 6);
                        S2 = sixSigFig((PivotPoint - (high - low)), 6);
                        R1 = sixSigFig(((PivotPoint * 2) - low), 6);
                        R2 = sixSigFig((PivotPoint + (high - low)), 6);
                        lastMajor = sixSigFig(parseFloat(major), 6);
                        lastMinor = sixSigFig(parseFloat(minor), 6);
                        latestema200 = sixSigFig(parseFloat(ema200), 6);
                        latestatr = sixSigFig(parseFloat(atr), 6);
                    }

                    // EMA STACK PROCESSING ****************************************************************************************
                    // Check the conditions for the EMAs.
                    if (close > ema10 && ema10 > ema20 && ema20 > ema50 && ema50 > ema100 && ema100 > ema200) {
                        emaStack = "Y";
                    } else if (close > ema10 && close > ema20 && close > ema50 && close > ema100 && close > ema200) {
                        emaStack = "Y-";
                    } else if (close < ema10 && ema10 < ema20 && ema20 < ema50 && ema50 < ema100 && ema100 < ema200) {
                        emaStack = "N";
                    } else if (close < ema10 && close < ema20 && close < ema50 && close < ema100 && close < ema200) {
                        emaStack = "N-";
                    } else {
                        emaStack = "";
                    }

                    // EMA 10/20 BOUNCE PROCESSING *************************************************************************************
                    let counter = 1;
                    bounce = "";
                    if (close > ema10 && close > open && open > ema20 && low < ema10 && ema20 > ema200) {
                        // EMA BOUNCE BULL
                        //Check for possible BULL BOUNCE - look back until either 
                        // open/close below ema20 = NO BOUNCE OR open/close below ema10 = BOUNCE
                        //console.log("BULL BOUNCE ", symbol, processdate, close, open, ema10);
                        while (bounce === "" && counter < 20) {
                            nextData = data[counter];
                            const { open, close, ema10, ema20 } = nextData;

                            // ERROR Check Block
                            //console.log("BULL BOUNCE ", symbol, interval, open, close, high, low, ema10, ema20);

                            if (open < ema20 || close < ema20) {
                                bounce = "X";
                                break;
                            } else if (open > ema10 || close > ema10) {
                                bounce = "Y";
                                break;
                            }
                            counter++;
                        }

                    } else if (close < ema10 && close < open && low > ema10 && open < ema20 && ema20 < ema200) {
                        // EMA BOUNCE BEAR
                        //Check for possible BEAR BOUNCE - look back until either 
                        // open/close above ema20 = NO BOUNCE OR open/close below ema10 = BOUNCE

                        while (bounce === "" && counter < 20) {
                            nextData = data[counter];
                            const { open, close, ema10, ema20 } = nextData;

                            //console.log("BEAR BOUNCE ", symbol, interval, open, close, ema10, ema20);

                            if (open > ema20 || close > ema20) {
                                bounce = "X";
                                break;
                            } else if (open < ema10 || close < ema10) {
                                bounce = "N";
                                break;
                            }
                            counter++
                        }
                    }
                    //console.log("EMA Bounce", bounce);
                    // EMA200, EMA100 and EMA50 BOUNCE PROCESSING *************************************************************************************   
                    bounce200 = "";
                    bounce100 = "";
                    bounce50 = "";         
                    bounce200 = await priceBounce(data, "ema200");
                    bounce100 = await priceBounce(data, "ema100");
                    bounce50 = await priceBounce(data, "ema50");

                } else {
                    console.error('No data found for', tableName);
                }

                // BULLISH BEARISH PROCESSING ***************************************************************************
                let i = 0;
                if (data.length < 2) {
                    console.error('Not enough data to analyze.');
                    return null;
                }
                const first = data[i];
                let marketTrend = null;

                while (i < data.length - 1 && !marketTrend) {
                    const currentBar = data[i];
                    const prevBar = data[i + 1];

                    const isCurrentBarBullish = currentBar.close > currentBar.open;
                    const isPrevBarBullish = prevBar.close > prevBar.open;

                    if (isCurrentBarBullish && isPrevBarBullish) {
                        // Both bars are bullish
                        let j = i + 1;
                        while (j < data.length && !marketTrend) {
                            const bar = data[j];
                            // find prior bearish bar and see if it is engulfing
                            if (bar.close < bar.open) {  // bearish bar found
                                marketTrend = (bar.open > first.close) ? 'bear' : 'bull';
                                break;
                            }
                            j++;
                        }
                    } else if (!isCurrentBarBullish && !isPrevBarBullish) {
                        // Both bars are bearish
                        let j = i + 1;
                        while (j < data.length && !marketTrend) {
                            const bar = data[j];
                            // find prior bullish bar and see if it is engulfing
                            if (bar.close > bar.open) {  // bullish bar found
                                marketTrend = (bar.open < first.close) ? 'bull' : 'bear';
                                break;
                            }
                            j++;
                        }
                    } else if (isCurrentBarBullish && !isPrevBarBullish) {
                        // Current bar is bullish and the previous bar is bearish

                        if (currentBar.close > prevBar.open) {
                            marketTrend = "bull";
                        } else {
                            // check for the next bullish bar that opened below first bullish bar
                            let j = i + 1;
                            while (j < data.length && !marketTrend) {
                                const bar = data[j];
                                if (bar.close > bar.open) {  // bullish bar found
                                    marketTrend = (bar.open < first.open) ? 'bull' : 'bear';
                                    break;
                                }
                                j++;
                            }
                        }

                    } else if (!isCurrentBarBullish && isPrevBarBullish) {
                        // Current bar is bearish and the previous bar is bullish

                        if (currentBar.close < prevBar.open) {
                            marketTrend = 'bear';
                        } else {
                            let j = i + 1;
                            while (j < data.length && !marketTrend) {
                                const bar = data[j];
                                if (bar.close > bar.open) {  // bullish bar found
                                    marketTrend = (bar.open > first.open) ? 'bear' : 'bull';
                                    break;
                                }
                                j++;
                            }
                        }

                    }
                    i++;  // Move to the next bar (in terms of data sequence, but actually the previous bar in time)
                }
                updateAssetField(symbol, `${intervalCodes[interval]}trend`, marketTrend);
                updateAssetField(symbol, `${intervalCodes[interval]}emastack`, emaStack);
                updateAssetField(symbol, `${intervalCodes[interval]}emabounce`, bounce);
                updateAssetField(symbol, `${intervalCodes[interval]}200bounce`, bounce200);
                updateAssetField(symbol, `${intervalCodes[interval]}100bounce`, bounce100);
                updateAssetField(symbol, `${intervalCodes[interval]}50bounce`, bounce50);
                if (interval === "1day") {
                    updateAssetField(symbol, 'dailymajor', lastMajor);
                    updateAssetField(symbol, 'dailyminor', lastMinor);
                    updateAssetField(symbol, 'dailypivot', PivotPoint);
                    updateAssetField(symbol, 'dailypivots1', S1);
                    updateAssetField(symbol, 'dailypivots2', S2);
                    updateAssetField(symbol, 'dailypivotr1', R1);
                    updateAssetField(symbol, 'dailypivotr2', R2);
                    updateAssetField(symbol, 'dailyema200', latestema200);
                    updateAssetField(symbol, 'dailyatr', latestatr);
                    updateAssetField(symbol, 'lastdayclose', latestclose);
                }
                if (interval === "4h") {
                    updateAssetField(symbol, 'fourhourlypivot', PivotPoint);
                    updateAssetField(symbol, 'fourhourlypivots1', S1);
                    updateAssetField(symbol, 'fourhourlypivots2', S2);
                    updateAssetField(symbol, 'fourhourlypivotr1', R1);
                    updateAssetField(symbol, 'fourhourlypivotr2', R2);
                    updateAssetField(symbol, 'fourhourlyema200', latestema200);
                    updateAssetField(symbol, 'fourhourlyatr', latestatr);
                }
                if (interval === "1min") {
                    
                    let assetRecords = await fetchAsset(symbol);
                    //console.log("headroom calcs ", assetRecords);
                    
                    let { rsBelow, rsAbove } = findNearestSupportLevels(assetRecords, latestclose);
                    //console.log("rsBelow: ", rsBelow, "rsAbove :", rsAbove);

                    let headroom = rsAbove - latestclose;
                    let floorroom = latestclose - rsBelow;
                    const headratio = headroom / assetRecords.dailyatr;
                    const floorratio = floorroom / assetRecords.dailyatr;
                    const headroomatrratio = headratio.toFixed(2);
                    const floorroomatrratio = floorratio.toFixed(2);
                    headroom = headroom.toFixed(4);
                    floorroom = floorroom.toFixed(4);
                    updateAssetField(symbol, 'belowrs', sixSigFig(rsBelow, 6));
                    updateAssetField(symbol, 'abovers', sixSigFig(rsAbove, 6));
                    updateAssetField(symbol, 'headroom', headroom);
                    updateAssetField(symbol, 'floorroom', floorroom);
                    updateAssetField(symbol, 'headroomatrratio', headroomatrratio);
                    updateAssetField(symbol, 'floorroomatrratio', floorroomatrratio);

                    updateAssetField(symbol, 'lastclose', latestclose);
                    updateAssetField(symbol, 'updated', processdate);
                }
            } catch (error) {
                console.error(error);
                return null;
            }
        }
    }
}

async function fetchData(tableName) {
    try {
        //console.log("Analyse Markets FetchData called for ", tableName);
        const currentVersion = await getCurrentVersion();
        const db = await openDB('AssetsDatabase', currentVersion);
        const transaction = db.transaction(tableName, 'readonly');
        const objectStore = transaction.objectStore(tableName);
        let allRecords;
        // if (endDate) { // If endDate is not an empty string, use it to filter records
        //    const dateRange = IDBKeyRange.upperBound(endDate);
        //   console.log("Input Date processing", endDate);
        //   allRecords = await objectStore.index('date').getAll(dateRange);
        // } else { // If endDate is an empty string, fetch all records without filtering
        allRecords = await objectStore.getAll();
        //console.log("Normal Date processing");
        // }
        db.close();
        return transformData(allRecords.reverse());
    } catch (error) {
        console.error(`Error fetching data: ${error.message}`);
        throw error;  // You may want to throw the error to handle it further up in your code
    }
}

function transformData(initialData) {
    return initialData.map(item => ({
        date: item.date,
        open: parseFloat(item.open),
        low: parseFloat(item.low),
        high: parseFloat(item.high),
        close: parseFloat(item.close),
        volume: parseInt(item.volume, 10),
        major: parseFloat(item.major),
        minor: parseFloat(item.minor),
        ema10: parseFloat(item.ema10),
        ema20: parseFloat(item.ema20),
        ema50: parseFloat(item.ema50),
        ema100: parseFloat(item.ema100),
        ema200: parseFloat(item.ema200),
        atr: parseFloat(item.atr)
    }));
}
export default analyseMarket;

function findNearestSupportLevels(assets, latestclose) {
    // Extract the support levels from the assets object - only rs 1 to 10 NOT rs11 to 20
    const supportLevels = Object.keys(assets)
    .filter(key => {
        if (!key.startsWith('rs') || assets[key] === '') {
            return false;
        }
        const num = parseInt(key.substring(2)); // Extract the number part from the key
        return num >= 1 && num <= 10; // Check if the number is within the range 1 to 10
    })
    .map(key => parseFloat(assets[key])) // Convert to numbers
    .filter(value => !isNaN(value)); // Remove any non-numeric values after parsing


    // Split the filtered support levels into above and below latestclose price
    let rsBelowArray = supportLevels.filter(value => value < latestclose).sort((a, b) => b - a);
    let rsAboveArray = supportLevels.filter(value => value > latestclose).sort((a, b) => a - b);

    // Get the nearest support levels
    let rsBelow = Math.max(...rsBelowArray)
    let rsAbove = Math.min(...rsAboveArray)
    //console.log("rsBelow: ", rsBelow, "rsAbove :", rsAbove);
    // Return the nearest support levels
    return { rsBelow, rsAbove };
}

