// GetData.js

import axios from 'axios';
import { getAssets, getCurrentVersion, fetchValue } from './db';
import { openDB } from 'idb';
import moment from 'moment-timezone';

const defaultIntervals = ["1month", "1week", "1day", "1h", "4h", "15min", "1min"];

export const getData = async (assetSymbol = "", intervals = "", inputEndDate = "") => {
    let closeDB;  // Declare closeDB at this scope so it's accessible in the finally block
    let updatedIntervals = ["1min"];
    let url;
    //console.log(assetSymbol);
    //console.log(intervals);

    // specify how far back the data set needs to extend
    const intervalBars = {
        "1month": 200,
        "1week": 200,
        "1day": 5000,
        "4h": 200,
        "1h": 800,
        "15min": 200,
        "1min": 300
    };

    // Determine the assets and intervals to use based on the parameters
    const assets = assetSymbol ? (typeof assetSymbol === 'string' ? [{ symbol: assetSymbol }] : assetSymbol) : await getAssets();
    const effectiveIntervals = intervals && intervals.length ? intervals : defaultIntervals;
    //console.log(assets);
    console.log(effectiveIntervals);

    try {
        const db = await openDB('AssetsDatabase');
        closeDB = () => db.close();  // Assign closeDB here
        await fetchDataAndStore(db, assets, effectiveIntervals);
    } catch (error) {
        console.error('Error in getData:', error);
    } finally {
        if (closeDB) {
            closeDB();  // Close the database connection
        }
    }
    async function fetchDataAndStore(db, assets, intervals) {
        try {
            for (const symbol of assets) {
                const assetSymbol = symbol.replace('/', '');
                const symbolType = await fetchValue(symbol, "assettype");
                console.log("Asset", symbol);

                for (const interval of intervals) {
                    const tableName = `${symbol.replace('/', '_')}-${interval}`;
                    //console.log("TableName: ", tableName);

                    let endDate;
                    if (inputEndDate === "") {
                        endDate = calculateEndDate(new Date(), interval);
                        //console.log("calculated endDate ", endDate);
                    } else {
                        //console.log("parsed endDate ", endDate);
                        endDate = parseEndDate(inputEndDate, interval);
                    }

                    //let startDate;
                    const inputStartDate = await fetchLastDate(tableName);
                    //console.log("inputStartDate ", inputStartDate);

                    if (symbolType === "I") {
                        // Test to ignore a a partial record for twelveData 

                        if (isMoreThanIntervalAgo(inputStartDate, interval) || inputStartDate === "") {
                            if (inputStartDate === "") {
                                url = `https://api.twelvedata.com/time_series?apikey=${process.env.REACT_APP_TWELVE_API_KEY}&interval=${interval}&symbol=${symbol}&timezone=Europe/London&format=JSON&outputsize=${intervalBars[interval]}`;
                            } else {
                                //startDate = calculateStartDate(inputStartDate, interval);

                                url = `https://api.twelvedata.com/time_series?apikey=${process.env.REACT_APP_TWELVE_API_KEY}&interval=${interval}&symbol=${symbol}&timezone=Europe/London&format=JSON&outputsize=${intervalBars[interval]}&start_date=${inputStartDate}`;
                            }
                            //console.log(url);
                            try {
                                const response = await axios.get(url);
                                //console.log(response);
                                if (response.data && response.data.values) {
                                    if (!db.objectStoreNames.contains(tableName)) {
                                        console.error(`Object store ${tableName} does not exist. Make sure to create it in the onupgradeneeded event handler.`);
                                        return;
                                    }

                                    let transaction = db.transaction(tableName, "readwrite");
                                    let objectStore = transaction.objectStore(tableName);

                                    for (const value of response.data.values) {
                                        let request = objectStore.put({
                                            symbol: symbol,
                                            interval: interval,
                                            date: value.datetime,
                                            open: value.open,
                                            close: value.close,
                                            high: value.high,
                                            low: value.low,
                                            volume: value.volume,
                                            ema10: "0",
                                            ema20: "0",
                                            ema50: "0",
                                            ema100: "0",
                                            ema200: "0",
                                            atr: "0",
                                            major: "0",
                                            minor: "0"
                                        });

                                        request.onerror = function (event) {
                                            console.error("Error storing data:", event.target.errorCode);
                                        };
                                    }

                                    await new Promise((resolve, reject) => {
                                        transaction.oncomplete = resolve;
                                        transaction.onerror = event => {
                                            console.error('Transaction error:', event);
                                            reject(event);
                                        };
                                    });
                                    if (!updatedIntervals.includes(interval)) {
                                        updatedIntervals.push(interval); // add interval to return array showing updated 
                                        //console.log("Updated interval: ", interval)
                                    }

                                } else {
                                    console.error(`No data returned for ${symbol} at interval ${interval}`);
                                }
                            } catch (error) {
                                console.error(`Error fetching data for ${symbol} at interval ${interval}:`, error);
                            }

                        }
                    } else {

                        // Use aLpha vantage data for everything else
                        const hasData = await checkTableNotEmpty(db, tableName);
                        let outSize;
                        if (hasData) {
                            outSize = "compact";
                        } else {
                            outSize = "full";
                        }

                        switch (interval) {
                            case "1min":
                                url = `https://www.alphavantage.co/query?function=TIME_SERIES_INTRADAY&symbol=${assetSymbol}&interval=1min&outputsize=${outSize}&apikey=${process.env.REACT_APP_ALPHAVANTAGE_API_KEY}`;
                                break;
                            case "15min":
                                url = `https://www.alphavantage.co/query?function=TIME_SERIES_INTRADAY&symbol=${assetSymbol}&interval=15min&outputsize=${outSize}&apikey=${process.env.REACT_APP_ALPHAVANTAGE_API_KEY}`;
                                break;
                            case "1h":
                                url = `https://www.alphavantage.co/query?function=TIME_SERIES_INTRADAY&symbol=${assetSymbol}&interval=60min&outputsize=${outSize}&apikey=${process.env.REACT_APP_ALPHAVANTAGE_API_KEY}`;
                                break;
                            case "4h":
                                break;
                            case "1day":
                                url = `https://www.alphavantage.co/query?function=TIME_SERIES_DAILY&symbol=${assetSymbol}&outputsize=${outSize}&apikey=${process.env.REACT_APP_ALPHAVANTAGE_API_KEY}`;
                                break;
                            case "1week":
                                url = `https://www.alphavantage.co/query?function=TIME_SERIES_WEEKLY&symbol=${assetSymbol}&outputsize=${outSize}&apikey=${process.env.REACT_APP_ALPHAVANTAGE_API_KEY}`;
                                break;
                            case "1month":
                                url = `https://www.alphavantage.co/query?function=TIME_SERIES_MONTHLY&symbol=${assetSymbol}&outputsize=${outSize}&apikey=${process.env.REACT_APP_ALPHAVANTAGE_API_KEY}`;
                                break;
                            default:
                        }
                        console.log("Interval: ", interval);

                        if (interval === "4h") {
                            const oneHourTable = `${symbol.replace('/', '_')}-1h`;
                            const fourHourTable = `${symbol.replace('/', '_')}-4h`;
                            if (hasData) {
                                await updateFourHourData(oneHourTable, fourHourTable);
                            } else {
                                await fourHourData(oneHourTable, fourHourTable);
                            }
                        } else {

                            try {
                                const response = await axios.get(url);
                                if (response.data && (response.data['Time Series (1min)'] || response.data['Time Series (15min)'] || response.data['Time Series (60min)'] || response.data['Time Series (Daily)'] || response.data['Weekly Time Series'] || response.data['Monthly Time Series'])) {
                                    let timeSeries;
                                    switch (interval) {
                                        case "1min":
                                            timeSeries = response.data['Time Series (1min)'];
                                            break;
                                        case "15min":
                                            timeSeries = response.data['Time Series (15min)'];
                                            break;
                                        case "1h":
                                            timeSeries = response.data['Time Series (60min)'];
                                            break;
                                        case "1day":
                                            timeSeries = response.data['Time Series (Daily)'];
                                            break;
                                        case "1week":
                                            timeSeries = response.data['Weekly Time Series'];
                                            break;
                                        case "1month":
                                            timeSeries = response.data['Monthly Time Series'];
                                            break;
                                        default:
                                    }
                                    //const tableName = `${assetSymbol.replace('/', '')}-${interval}`;


                                    if (!db.objectStoreNames.contains(tableName)) {
                                        console.error(`Object store ${tableName} does not exist.`);
                                        return;
                                    }

                                    let isFirstRecord = true; // Flag to check if we're processing the first record
                                    let lastEntryDateString = await fetchLastDate(tableName); // Fetch the last entry date string from the table
                                    let lastEntryDate = lastEntryDateString ? new Date(lastEntryDateString) : null;
                                    //console.log("isFirstRecord ", isFirstRecord, "lastEntryDateString ", lastEntryDateString, "lastEntryDate ", lastEntryDate);

                                    let transaction = db.transaction(tableName, "readwrite");
                                    let objectStore = transaction.objectStore(tableName);
                                    let counter = 0;

                                    for (const [date, timeData] of Object.entries(timeSeries)) {

                                        // Add 5 hours because Alpha Vantage data is in US/Eastern time and needs to be set to London/UK time
                                        //let recordDate = new Date(date);
                                        //recordDate.setHours(recordDate.getHours() + 5);

                                        let recordDate = convertEasternToLondon(date);


                                        // Only check the first record for completeness based on the interval
                                        if (isFirstRecord) {
                                            isFirstRecord = false; // Ensure the check is not performed again
                                            if (!isCompleteRecord(recordDate, interval)) {
                                                //console.log(`Skipping incomplete record for date: ${date} and interval ${interval}`);
                                                continue; // Skip the rest of the loop for the current iteration
                                            }
                                        }

                                        // Skip processing if this record's date is not newer than the last entry's date
                                        if (lastEntryDate && recordDate <= lastEntryDate) {
                                            continue; // Move to the next record
                                        }

                                        // formattedDate function includes a +5 hours component
                                        const formattedDate = formatDate(recordDate, interval);
                                        let record = {
                                            symbol: symbol,
                                            interval: interval,
                                            date: formattedDate,
                                            open: parseFloat(timeData['1. open']),
                                            close: parseFloat(timeData['4. close']),
                                            high: parseFloat(timeData['2. high']),
                                            low: parseFloat(timeData['3. low']),
                                            volume: parseInt(timeData['5. volume']),
                                            ema10: "0",
                                            ema20: "0",
                                            ema50: "0",
                                            ema100: "0",
                                            ema200: "0",
                                            atr: "0",
                                            major: "0",
                                            minor: "0"
                                        };
                                        //console.log(record);

                                        let request = objectStore.put(record);

                                        request.onerror = function (event) {
                                            console.error("Error storing data:", event.target.errorCode);
                                        };
                                        counter = counter + 1;
                                        if (counter > (1 + intervalBars[interval])) {
                                            break;
                                        }
                                    }

                                    await new Promise((resolve, reject) => {
                                        transaction.oncomplete = resolve;
                                        transaction.onerror = event => {
                                            console.error('Transaction error:', event);
                                            reject(event);
                                        };
                                    });

                                    if (!updatedIntervals.includes(interval)) {
                                        updatedIntervals.push(interval); // add interval to return array showing updated 
                                    }

                                } else {
                                    console.error(`No data returned for ${symbol} at interval ${interval}`);
                                }
                            } catch (error) {
                                console.error(`Error fetching data for ${symbol} at interval ${interval}:`, error);
                            }

                        }


                    }
                }
            }
        } catch (error) {
            console.error('Error in fetchDataAndStore:', error);
        }
    }

    function calculateEndDate(inputDate, interval) {
        const date = new Date(inputDate);
        date.setSeconds(0);
        //console.log("calculate end Date");

        function formatDate(date, includeTime = false) {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed in JavaScript
            const day = date.getDate().toString().padStart(2, '0');
            if (includeTime) {
                const hour = date.getHours().toString().padStart(2, '0');
                const minute = date.getMinutes().toString().padStart(2, '0');
                const second = date.getSeconds().toString().padStart(2, '0');
                return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
            }
            return `${year}-${month}-${day}`;
        }

        switch (interval) {
            case '1month':
                date.setDate(1);
                date.setHours(0, 0, 0, 0);
                date.setDate(date.getDate() - 1);  // Move back by one day
                return formatDate(date);
            case '1week':
                const diff = date.getDay() - 1;
                date.setDate(date.getDate() - diff);
                date.setHours(0, 0, 0, 0);
                date.setDate(date.getDate() - 1);  // Move back by one day
                return formatDate(date);
            case '1day':
                date.setHours(0, 0, 0, 0);
                date.setDate(date.getDate() - 1);  // Move back by one day
                return formatDate(date);
            case '4h':
                const hour4h = Math.floor(date.getHours() / 4) * 4;
                date.setHours(hour4h, 0, 0, 0);
                date.setMinutes(date.getMinutes() - 1);  // Move back by one minute
                return formatDate(date, true);
            case '1h':
                date.setMinutes(0, 0, 0);
                date.setMinutes(date.getMinutes() - 1);  // Move back by one minute
                return formatDate(date, true);
            case '15min':
                const minute15 = Math.floor(date.getMinutes() / 15) * 15;
                date.setMinutes(minute15, 0, 0);
                date.setMinutes(date.getMinutes() - 1);  // Move back by one minute
                return formatDate(date, true);
            case '1min':
                date.setSeconds(0, 0);
                return formatDate(date, true);
            default:
                throw new Error('Invalid interval');
        }
    }
    return updatedIntervals;
}


// GET PRICE DATA FROM PRICE TABLES ***********************************************************
export async function fetchLastDate(tableName) {
    const currentVersion = await getCurrentVersion();
    const db = await openDB('AssetsDatabase', currentVersion);
    const transaction = db.transaction(tableName, 'readonly');
    const objectStore = transaction.objectStore(tableName);
    const allRecords = await objectStore.getAll();

    db.close();

    if (allRecords.length === 0) {
        return "";
    }

    const lastRecord = allRecords[allRecords.length - 1];
    //console.log("last Date", lastRecord.date);
    return lastRecord.date; // Assuming the date field is named 'date'

}

//********************************************************************************************** */
function isMoreThanIntervalAgo(dateTimeStr, interval) {
    const dateTime = new Date(dateTimeStr);
    const now = new Date();

    // Calculate the difference in milliseconds
    const difference = now - dateTime;

    // Convert the interval to milliseconds
    let intervalMilliseconds;
    switch (interval) {
        case "1month":
            intervalMilliseconds = 1000 * 60 * 60 * 24 * 28; // milliseconds in one day
            break;
        case "1week":
            intervalMilliseconds = 1000 * 60 * 60 * 24 * 7; // milliseconds in one day
            break;
        case "1day":
            intervalMilliseconds = 1000 * 60 * 60 * 24; // milliseconds in one day
            break;
        case "4h":
            intervalMilliseconds = 1000 * 60 * 60 * 4; // milliseconds in four hours
            break;
        case "1h":
            intervalMilliseconds = 1000 * 60 * 60; // milliseconds in one hour
            break;
        case "15min":
            intervalMilliseconds = 1000 * 60 * 15; // milliseconds in fifteen minutes
            break;
        case "1min":
            intervalMilliseconds = 0; // automatically false
            break;
        default:
            throw new Error("Invalid interval");
    }
    //console.log("Last data date: ", dateTimeStr, " Current Date: ", now, " Difference ", difference, "is it bigger than  Interval ms: ", intervalMilliseconds);
    return difference > intervalMilliseconds * 2;
    // datetime in data is start of interval and was downloaded at end of interval 
    // so 2 x as don't need to look until the following interval
}

//********************************************************************************************** */
function parseEndDate(endDateString, interval) {
    if (!endDateString) return null;

    if (["1month", "1week", "1day"].includes(interval)) {
        let parsedEndDate = new Date(endDateString);
        // For '1month', '1week', '1day', convert to "yyyy-mm-dd" format without time.
        return parsedEndDate.toISOString().split('T')[0];
    } else {
        return endDateString;
    }
}

//********************************************************************************************** */
async function checkTableNotEmpty(db, tableName) {
    if (!db.objectStoreNames.contains(tableName)) {
        console.error(`Object store ${tableName} does not exist.`);
        return false;
    }

    const transaction = db.transaction(tableName, 'readonly');
    const objectStore = transaction.objectStore(tableName);
    const count = await objectStore.count();
    return count > 0;
}

//********************************************************************************************** */
function formatDate(timestamp, interval) {
    // Create a Date object from the timestamp
    const date = new Date(timestamp);

    // Function to pad single digit numbers with a leading zero
    const pad = (num) => num.toString().padStart(2, '0');

    // Extract year, month, day, hour, and minute
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // getMonth() returns 0-11
    const day = pad(date.getDate());
    const hour = pad(date.getHours());
    const minute = pad(date.getMinutes());

    // Format the date based on the interval
    if (interval === "1day" || interval === "1month" || interval === "1week") {
        return `${year}-${month}-${day}`;
    } else {
        return `${year}-${month}-${day} ${hour}:${minute}:00`;
    }
}

//********************************************************************************************** */
async function fourHourData(oneHourTable, fourHourTable) {
    const currentVersion = await getCurrentVersion();
    const db = await openDB('AssetsDatabase', currentVersion);
    const transaction1h = db.transaction(oneHourTable, 'readonly');
    const objectStore1h = transaction1h.objectStore(oneHourTable);
    const oneHourData = await objectStore1h.getAll();
    //console.log("ONE HOUR ", oneHourData);
    const aggregatedData = await aggregateDataIntoFourHourPeriods(oneHourData);

    // Save the aggregated data into the fourHourTable
    const transaction4h = db.transaction(fourHourTable, 'readwrite');
    const objectStore4h = transaction4h.objectStore(fourHourTable);

    for (const record of aggregatedData) {
        await objectStore4h.put(record);
    }

    await transaction4h.done; // Corrected from transaction4h.done
    db.close();
}

//********************************************************************************************** */
async function aggregateDataIntoFourHourPeriods(data) {
    const aggregatedData = [];

    // Group data by their 4-hour period, assuming oneHourData is the correct data source
    const groupedData = data.reduce((acc, record) => {
        const date = new Date(record.date);
        const periodIndex = Math.floor(date.getHours() / 4);
        const periodKey = `${date.toISOString().split('T')[0]}-${periodIndex}`;

        if (!acc[periodKey]) {
            acc[periodKey] = [];
        }
        acc[periodKey].push(record);

        return acc;
    }, {});

    // Aggregate each group into a single record
    for (const [_, fourHourPeriodData] of Object.entries(groupedData)) {
        const open = fourHourPeriodData[0].open; // Open of the first available data point
        const close = fourHourPeriodData[fourHourPeriodData.length - 1].close; // Close of the last available data point
        const high = Math.max(...fourHourPeriodData.map(d => d.high));
        const low = Math.min(...fourHourPeriodData.map(d => d.low));
        const volume = fourHourPeriodData.reduce((acc, d) => acc + d.volume, 0);

        const aggregatedRecord = {
            symbol: fourHourPeriodData[0].symbol,
            interval: '4h',
            date: fourHourPeriodData[0].date, // Date of the first record in the 4-hour period
            open: open,
            close: close,
            high: high,
            low: low,
            volume: volume,
            ema10: "0",
            ema20: "0",
            ema50: "0",
            ema100: "0",
            ema200: "0",
            atr: "0",
            major: "0",
            minor: "0"
        };
        //console.log("FOUR HOUR ", aggregatedRecord);

        aggregatedData.push(aggregatedRecord);
    }
    //console.log("TYPE: ", typeof aggregatedData, aggregatedData);
    return aggregatedData;

}
//********************************************************************************************** */

async function updateFourHourData(oneHourTable, fourHourTable) {
    // First, check if the current time is exactly at one of the 4-hour intervals
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();

    // Proceed only if it's exactly on the hour and a multiple of 4 (excluding minutes and seconds)
    if (minutes === 0 && seconds === 0 && hours % 4 === 0) {
        const currentVersion = await getCurrentVersion();
        const db = await openDB('AssetsDatabase', currentVersion);

        // Attempt to fetch the last entry to ensure we are not re-aggregating data unnecessarily
        const lastEntry = await fetchLastEntry(fourHourTable, db);
        if (lastEntry) {
            // Fetch new 1-hour data that has been recorded after the last 4-hour entry
            const newOneHourData = await fetchNewDataFromTable(oneHourTable, lastEntry.date, db);

            // Check if there is new data to aggregate
            if (newOneHourData && newOneHourData.length > 0) {
                const aggregatedData = await aggregateDataIntoFourHourPeriods(newOneHourData);

                // Save the aggregated data into the fourHourTable
                const transaction = db.transaction(fourHourTable, 'readwrite');
                const objectStore = transaction.objectStore(fourHourTable);

                for (const record of aggregatedData) {
                    await objectStore.put(record);
                }

                await transaction.done;
            }
        }

        db.close();
    } else {
        console.log('Not the right time for 4-hour aggregation. Current time:', now.toISOString());
    }
}


//********************************************************************************************** */
async function fetchLastEntry(tableName, db) {
    const transaction = db.transaction(tableName, 'readonly');
    const objectStore = transaction.objectStore(tableName);
    const allRecords = await objectStore.getAll();
    await transaction.done;

    return allRecords[allRecords.length - 1];
}

//********************************************************************************************** */
async function fetchNewDataFromTable(tableName, lastDate, db) {
    const transaction = db.transaction(tableName, 'readonly');
    const objectStore = transaction.objectStore(tableName);
    const allRecords = await objectStore.getAll();
    await transaction.done;

    return allRecords.filter(record => new Date(record.date) > new Date(lastDate));
}

//********************************************************************************************** */
function isCompleteRecord(recordDate, interval) {
    const now = new Date();
    switch (interval) {
        case '1month':
            return !(recordDate.getFullYear() === now.getFullYear() && recordDate.getMonth() === now.getMonth());
        case '1week':
            // Calculate the end of the current week (Friday)
            const endOfWeek = new Date(now);
            endOfWeek.setDate(now.getDate() + (5 - now.getDay())); // Adjust to the upcoming Friday
            return recordDate < endOfWeek;
        case '1day':
            return !(recordDate.getFullYear() === now.getFullYear() && recordDate.getMonth() === now.getMonth() && recordDate.getDate() === now.getDate());
        case '1h':
            // For hourly, check if the record falls before the start of the next hour
            const nextHour = new Date(now);
            nextHour.setHours(now.getHours() + 1, 0, 0, 0); // Set to the start of the next hour
            //console.log("Current record: ", recordDate,"Next hour: ", nextHour);
            return recordDate < nextHour;
        case '15min':
            // For 15min interval, check if the record falls before the start of the next 15-minute block
            const next15Min = new Date(now);
            next15Min.setMinutes(Math.floor(now.getMinutes() / 15) * 15 + 15, 0, 0); // Adjust to the start of the next 15-minute block
            return recordDate < next15Min;
        case '1min':
            // For 1min interval, check if the record falls before the start of the next minute
            const nextMinute = new Date(now);
            nextMinute.setMinutes(now.getMinutes() + 1, 0, 0); // Adjust to the start of the next minute
            return recordDate < nextMinute;
        default:
            return true;
    }
}


//********************************************************************************************** */
function convertEasternToLondon(dateString) {
    let format = 'YYYY-MM-DD HH:mm:ss'; // The format of your input dateString

    // Parse the date in US/Eastern time zone with the specified format
    let easternTime = moment.tz(dateString, format, "America/New_York");

    // Convert to London time
    let londonTime = easternTime.clone().tz("Europe/London");

    // Return a JavaScript Date object
    return londonTime.toDate();
}