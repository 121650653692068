//GetFibonacci.js

import { getAssets, updateAssetField, fetchAsset } from './db.js';
import { openDB } from 'idb';

export async function getFibonacci(assetSymbol = "") {

    //console.log('getFibonacci called with symbols:', assetSymbol);
    const assets = assetSymbol ? (typeof assetSymbol === 'string' ? [{ symbol: assetSymbol }] : assetSymbol) : await getAssets();

    for (const symbol of assets) {
        let direction;
        let assetRecord = await fetchAsset(symbol);
        const fibHighDate = assetRecord.fibhighdate;
        const fibLowDate = assetRecord.fiblowdate;
        if (fibHighDate < fibLowDate) {
            direction = "ascending";
        } else {
            direction = "descending";
        }

        let fibHighPrice = await fetchPriceByDate(symbol, fibHighDate, 'high');
        let fibLowPrice = await fetchPriceByDate(symbol, fibLowDate, 'low');
        //console.log("fibHighPrice before parse ",fibHighPrice,"fibLowPrice before parse ", fibLowPrice);
        if (fibHighPrice && fibLowPrice) {

            fibHighPrice = parseFloat(fibHighPrice);
            fibLowPrice = parseFloat(fibLowPrice);

            const priceRange = fibHighPrice - fibLowPrice;
            const fib0 = direction === 'ascending' ? fibLowPrice : fibHighPrice;
            const fib100 = direction === 'ascending' ? fibHighPrice : fibLowPrice;
            const fib23 = direction === 'ascending' ? fib0 + priceRange * 0.236 : fib100 - priceRange * 0.236;
            const fib38 = direction === 'ascending' ? fib0 + priceRange * 0.382 : fib100 - priceRange * 0.382;
            const fib50 = direction === 'ascending' ? fib0 + priceRange * 0.5 : fib100 - priceRange * 0.5;
            const fib61 = direction === 'ascending' ? fib0 + priceRange * 0.618 : fib100 - priceRange * 0.618;
            const fib76 = direction === 'ascending' ? fib0 + priceRange * 0.764 : fib100 - priceRange * 0.764;

            //console.log("fibHighPrice ",fibHighPrice,"fibLowPrice ", fibLowPrice,"priceRange ", priceRange, "fib0 ",fib0, "fib23 ",fib23, "fib38 ",fib38, "fib50 ", fib50, "fib61 ", fib61, "fib76 ", fib76, "fib100 ", fib100);

            updateAssetField(symbol, `fibhighprice`, fibHighPrice);
            updateAssetField(symbol, `fibLowprice`, fibLowPrice);
            updateAssetField(symbol, `fib0`, fib0);
            updateAssetField(symbol, `fib23`, fib23);
            updateAssetField(symbol, `fib38`, fib38);
            updateAssetField(symbol, `fib50`, fib50);
            updateAssetField(symbol, `fib61`, fib61);
            updateAssetField(symbol, `fib76`, fib76);
            updateAssetField(symbol, `fib100`, fib100);
        }
    }
}

async function fetchPriceByDate(symbol, date, priceType) {
    const tableName = `${symbol.replace('/', '_')}-1day`;
    let price = null;

    const db = await openDB('AssetsDatabase');
    const transaction = db.transaction(tableName, 'readonly');
    const store = transaction.objectStore(tableName);
    const index = store.index('date');
    const records = await index.getAll(IDBKeyRange.only(date));

    if (records && records.length > 0) {
        const record = records[0];
        price = record[priceType];
    } else {
        console.log("date for Fibonacci not found ", symbol, date, priceType);
    }

    db.close();
    return price;
}
