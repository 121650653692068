// TradeHunter.js

import { fetchAsset, fetchPrices, updateAssetField, getAssets } from './db';
import { priceBounce } from './PriceBounce';

let currDayBar, prevDayBar, prevPrevDayBar, ema1daybull, ema1daybear, ema4hbull, ema4hbear, ema1hbull, ema1hbear, trendBounce, trendBounce4h, trendBounce1h, dailyTrend, currDayClose, close4h, close1h;
let fib0Bounce,fib23Bounce,fib38Bounce,fib50Bounce,fib61Bounce,fib76Bounce,fib100Bounce;
// Runs every hour to check for potential trade Setups and Plays Part 1
export const tradeHunter = async (assetSymbol = "") => {
    // Get all data 
    console.log("Running Hunter");
    let asset, pricesDay, prices4h, prices1h;
    const assets = assetSymbol ? (typeof assetSymbol === 'string' ? [{ symbol: assetSymbol }] : assetSymbol) : await getAssets();
    for (const symbol of assets) {
        //console.log("Running Hunter:", symbol);
        asset = await fetchAsset(symbol);
        pricesDay = await fetchPrices(symbol, "1day", true);
        prices4h = await fetchPrices(symbol, "4h", true);
        prices1h = await fetchPrices(symbol, "1h", true);

        //const atrPercentClose = 0.2;
        close4h = parseFloat(prices4h[0].close);
        close1h = parseFloat(prices1h[0].close);
        const currDayOpen = parseFloat(pricesDay[0].open);
        currDayClose = parseFloat(pricesDay[0].close);
        const currDayLow = parseFloat(pricesDay[0].low);
        const currDayHigh = parseFloat(pricesDay[0].high);
        const prevDayOpen = parseFloat(pricesDay[1].open);
        const prevDayClose = parseFloat(pricesDay[1].close);
        const prevPrevDayOpen = parseFloat(pricesDay[2].open);
        const prevPrevDayClose = parseFloat(pricesDay[2].close);
        const pp1day = parseFloat(asset.dailypivot);
        const majorTrend = parseFloat(asset.dailymajor);
        const minorTrend = parseFloat(asset.dailyminor);
        const majorDirection = parseFloat(asset.majordirection);
        const belowrs = parseFloat(asset.belowrs);
        const abovers = parseFloat(asset.abovers);
        const dailyemastack = asset.dailyemastack;
        const fourhourlyemastack = asset.fourhourlyemastack;
        const hourlyemastack = asset.hourlyemastack;
        const dailyema200 = parseFloat(asset.dailyema200);
        const monthlyTrend = asset.monthlytrend;
        const weeklyTrend = asset.weeklytrend;
        dailyTrend = asset.dailytrend;
        const fourHourlyEmaBounce = asset.fourhourlyemabounce;
        const hourlyEmaBounce = asset.hourlyemabounce;
        const dailyEmaBounce = asset.dailyemabounce;
        const fib0 = parseFloat(asset.fib0);
        const fib23 = parseFloat(asset.fib23);
        const fib38 = parseFloat(asset.fib38);
        const fib50 = parseFloat(asset.fib50);
        const fib61 = parseFloat(asset.fib61);
        const fib76 = parseFloat(asset.fib76);
        const fib100 = parseFloat(asset.fib100);
        //console.log(close4h, close1h, currDayOpen, currDayClose, currDayHigh, currDayLow, prevDayClose, prevDayOpen, pp1day);

        // Is last closed day bull or bear
        if (currDayOpen > currDayClose) {
            currDayBar = "bear";
        } else {
            currDayBar = "bull";
        }

        // Is teh previous day bull or bear
        if (prevDayOpen > prevDayClose) {
            prevDayBar = "bear";
        } else {
            prevDayBar = "bull";
        }

        // is teh day before that a bull or bear
        if (prevPrevDayOpen > prevPrevDayClose) {
            prevPrevDayBar = "bear";
        } else {
            prevPrevDayBar = "bull";
        }

        // Was the last closed day price above all the emas = bullish
        if (dailyemastack.slice(0, 1) === "Y") {
            ema1daybull = true;
        } else {
            ema1daybull = false;
        }

        // Was the last closed day price below all the emas = bearish
        if (dailyemastack.slice(0, 1) === "N") {
            ema1daybear = true;
        } else {
            ema1daybear = false;
        }

        // Was the last closed 4 hour price above all the emas = bullish
        if (fourhourlyemastack.slice(0, 1) === "Y") {
            ema4hbull = true;
        } else {
            ema4hbull = false;
        }

        // Was the last closed 4 hour price below all the emas = bearish
       if (fourhourlyemastack.slice(0, 1) === "N") {
            ema4hbear = true;
        } else {
            ema4hbear = false;
        }

        // Was the last closed 1 hour price above all the emas = bullish
        if (hourlyemastack.slice(0, 1) === "Y") {
            ema1hbull = true;
        } else {
            ema1hbull = false;
        }

        // Was the last closed 1 hour price below all the emas = bearish
        if (hourlyemastack.slice(0, 1) === "N") {
            ema1hbear = true;
        } else {
            ema1hbear = false;
        }

        // EARLY ADOPTER TRADES***************************************************************
        //***********************************************************************************

    
        let strTrade = "";

        // UP TRADES - weekly and monthly trend is bullish and all emas are below on 1 day prices - but current day is reversing direction 
        //*****************************************************************************************

        // BULL TRADES - REACTION = STILL COMING DOWN BUT ABOUT TO REVERSE ***********************************
        if ((monthlyTrend === "bull" && weeklyTrend === "bull" && currDayBar === "bear" && ema1daybull) ||
            (monthlyTrend === "bull" && weeklyTrend === "bull" && currDayBar === "bull" && prevDayBar === "bear" && currDayClose < prevDayOpen)) {
            
            // POTENTIAL REACTION/RETRACEMENT TRADE

            // RS SUPPORT 
            // Near to support below
            if (currDayClose > belowrs && currDayLow < belowrs) {
                strTrade = bullTrade("rs");
            }
            // EMA200 REACTION/RETRACEMENT
            // Near to support below
            if (currDayClose > dailyema200 && currDayLow < dailyema200) {
                strTrade = bullTrade("ema200");
            }
            // PIVOT POINT REACTION/RETRACEMENT 
            // Near to support below
            if (currDayClose > pp1day && currDayLow < pp1day) {
                strTrade = bullTrade("pp");
            }

            // MAJOR TREND REACTION/RETRACEMENT 
            // Near to support below
            if (currDayClose > majorTrend && currDayLow < majorTrend) {
                strTrade = bullTrade("maj");
            }

            // MINOR TREND REACTION/RETRACEMENT 
            // Near to support below
            if (currDayClose > minorTrend && currDayLow < minorTrend) {
                strTrade = bullTrade("min");
            }

            // Fibonacci's are zones - not exact to may close below so test it opened above
            // TradeTrigger.js will test for movement up above

           // F0 REACTION/RETRACEMENT
            // Near to support below
            if (currDayOpen > fib0 && currDayLow < fib0) {
                strTrade = bullTrade("fib0");
            }
           // F23 REACTION/RETRACEMENT
            // Near to support below
            if (currDayOpen > fib23 && currDayLow < fib23) {
                strTrade = bullTrade("fib23");
            }
           // F38 REACTION/RETRACEMENT
            // Near to support below
            if (currDayOpen > fib38 && currDayLow < fib38) {
                strTrade = bullTrade("fib38");
            }
           // F50 REACTION/RETRACEMENT
            // Near to support below
            if (currDayOpen > fib50 && currDayLow < fib50) {
                strTrade = bullTrade("fib50");
            }
           // F61 REACTION/RETRACEMENT
            // Near to support below
            if (currDayOpen > fib61 && currDayLow < fib61) {
                strTrade = bullTrade("fib61");
            }
           // F76 REACTION/RETRACEMENT
            // Near to support below
            if (currDayOpen > fib76 && currDayLow < fib76) {
                strTrade = bullTrade("fib76");
            }
           // F100 REACTION/RETRACEMENT
            // Near to support below
            if (currDayOpen > fib100 && currDayLow < fib100) {
                strTrade = bullTrade("fib100");
            }


        }
        //*******************************************************************************************************************
        // POTENTIAL MOMENTUM TRADE
        if (monthlyTrend === "bull" && weeklyTrend === "bull" && currDayBar === "bull" && prevDayBar === "bear" && currDayClose > prevDayOpen) {
            if (strTrade === "") {
                strTrade = "bull momentum";
            } else {
                strTrade = strTrade + " momentum";
            }

            if (ema4hbull) {
                strTrade = strTrade + " 4h";
            }
            if (ema1hbull) {
                strTrade = strTrade + " 1h";
            }
        }
       //*******************************************************************************************************************
        // POTENTIAL SLOW MOMENTUM TRADE
        if (monthlyTrend === "bull" && weeklyTrend === "bull" && currDayBar === "bull" && prevDayBar === "bull" && prevPrevDayBar === "bear" && currDayClose > prevPrevDayOpen) {
            if (strTrade === "") {
                strTrade = "bull slow momentum";
            } else {
                strTrade = strTrade + " slow momentum";
            }

            if (ema4hbull) {
                strTrade = strTrade + " 4h";
            }
            if (ema1hbull) {
                strTrade = strTrade + " 1h";
            }
        }

        // DOWN TRADES - weekly and monthly trend is bullish and all emas are above on 1 day prices
        //*****************************************************************************************
        // BEAR TRADES ***********************************
        if ((monthlyTrend === "bear" && weeklyTrend === "bear" && currDayBar === "bull" && ema1daybear) ||
            (monthlyTrend === "bear" && weeklyTrend === "bear" && currDayBar === "bear" && prevDayBar === "bull" && currDayClose > prevDayOpen)) {

            // POTENTIAL REACTION/RETRACEMENT TRADES

            // RS SUPPORT 
            // Near to support above
            if (currDayClose < abovers && currDayHigh > abovers) {
                strTrade = bearTrade("rs");
            }
            // EMA200 REACTION/RETRACEMENT
            // Near to support above
            if (currDayClose < dailyema200 && currDayHigh > dailyema200) {
                strTrade = bearTrade("ema200");
            }
            // PIVOT POINT REACTION/RETRACEMENT 
            // Near to support above
            if (currDayClose < pp1day && currDayHigh > pp1day) {
                strTrade = bearTrade("pp");
            }
            // MAJOR TREND REACTION/RETRACEMENT 
            // Near to support above
            if (currDayClose < majorTrend && currDayHigh > majorTrend) {
                strTrade = bearTrade("maj");
            }
            // MINOR TREND REACTION/RETRACEMENT 
            // Near to support above
            if (currDayClose < minorTrend && currDayHigh > minorTrend) {
                strTrade = bearTrade("min");
            }

            // Fibonacci's are zones - not exact to may close above so test it opened below
            // TradeTrigger.js will test for movement down below

           // F0 REACTION/RETRACEMENT
            // Near to support above
            if (currDayOpen < fib0 && currDayHigh > fib0) {
                strTrade = bearTrade("fib0");
            }
           // F23 REACTION/RETRACEMENT
            // Near to support above
            if (currDayOpen < fib23 && currDayHigh > fib23) {
                strTrade = bearTrade("fib23");
            }
           // F38 REACTION/RETRACEMENT
            // Near to support above
            if (currDayOpen < fib38 && currDayHigh > fib38) {
                strTrade = bearTrade("fib38");
            }
           // F50 REACTION/RETRACEMENT
            // Near to support above
            if (currDayOpen < fib50 && currDayHigh > fib50) {
                strTrade = bearTrade("fib50");
            }
           // F61 REACTION/RETRACEMENT
            // Near to support above
            if (currDayOpen < fib61 && currDayHigh > fib61) {
                strTrade = bearTrade("fib61");
            }
           // F76 REACTION/RETRACEMENT
            // Near to support above
            if (currDayOpen < fib76 && currDayHigh > fib76) {
                strTrade = bearTrade("fib76");
            }
           // F100 REACTION/RETRACEMENT
            // Near to support above
            if (currDayOpen < fib100 && currDayHigh > fib100) {
                strTrade = bearTrade("fib100");
            }
        }
        //*******************************************************************************************************************
        // POTENTIAL MOMENTUM TRADE

        if (monthlyTrend === "bear" && weeklyTrend === "bear" && currDayBar === "bear" && prevDayBar === "bull" && currDayClose < prevDayOpen) {

            if (strTrade === "") {
                strTrade = "bear momentum";
            } else {
                strTrade = strTrade + " momentum";
            }

            if (ema4hbear) {
                strTrade = strTrade + " 4h";
            }
            if (ema1hbear) {
                strTrade = strTrade + " 1h";
            }
        }

        await updateAssetField(symbol, 'readytradeet', strTrade);

       //*******************************************************************************************************************
        // POTENTIAL SLOW MOMENTUM TRADE

        if (monthlyTrend === "bear" && weeklyTrend === "bear" && currDayBar === "bear" && prevDayBar === "bear" && prevPrevDayBar === "bull"  && currDayClose < prevPrevDayOpen) {

            if (strTrade === "") {
                strTrade = "bear slow momentum";
            } else {
                strTrade = strTrade + " slow momentum";
            }

            if (ema4hbear) {
                strTrade = strTrade + " 4h";
            }
            if (ema1hbear) {
                strTrade = strTrade + " 1h";
            }
        }

        await updateAssetField(symbol, 'readytradeet', strTrade);

        // LAZY TRADER ***************************************************************
        //***********************************************************************************
        strTrade="";

        trendBounce = await priceBounce(pricesDay, "major");
        // BULL TRADES ***********************************
        // T1 MAJOR BOUNCE ******************************************************
        if (majorDirection === "up" && trendBounce === "bull" && ema1daybull ) {
            strTrade="bull T1 major 1D";
        }


        trendBounce4h = await priceBounce(prices4h, "major");
        // BULL TRADES ***********************************
        // T1 MAJOR BOUNCE ******************************************************
        if (majorDirection === "up" && trendBounce4h === "bull" && ema4hbull ) {
            strTrade="bull T1 major 4h";
        }


        trendBounce1h = await priceBounce(prices1h, "major");
        // BULL TRADES ***********************************
        // T1 MAJOR BOUNCE ******************************************************
        if (majorDirection === "up" && trendBounce1h === "bull" && ema1hbull ) {
            strTrade="bull T1 major 1h";
        }

        // T2 MINOR BREAK *******************************************************
        if (currDayClose > minorTrend && currDayOpen < minorTrend) {
            strTrade="bull T2 minor 1D";
        }

        // T2 DAILY EMA 10/20 BOUNCE **************************************************
        if (dailyEmaBounce === "Y" && monthlyTrend === "bull" && weeklyTrend === "bull") {
            strTrade="bull T2 1020 1D";
        }

        // T2 4 HOURLY EMA 10/20 BOUNCE **************************************************
        if (fourHourlyEmaBounce === "Y") {
            strTrade="bull T2 1020 4h";
        }

        // T2 1 HOURLY EMA 10/20 BOUNCE **************************************************
        if (hourlyEmaBounce === "Y") {
            strTrade="bull T2 1020 1h";
        }

        fib0Bounce = await priceBounce(pricesDay, "fib0");
        // BULL TRADES ***********************************
        // FIB BOUNCE ******************************************************
        if (majorDirection === "up" && fib0Bounce === "bull" && ema1daybull ) {
            strTrade="bull T2 Fib 0% 1D";
        }

        fib23Bounce = await priceBounce(pricesDay, "fib23");
        // BULL TRADES ***********************************
        // FIB BOUNCE ******************************************************
        if (majorDirection === "up" && fib23Bounce === "bull" && ema1daybull ) {
            strTrade="bull T2 Fib 23.6% 1D";
        }

        fib38Bounce = await priceBounce(pricesDay, "fib38");
        // BULL TRADES ***********************************
        // FIB BOUNCE ******************************************************
        if (majorDirection === "up" && fib38Bounce === "bull" && ema1daybull ) {
            strTrade="bull T2 Fib 38.2% 1D";
        }

        fib50Bounce = await priceBounce(pricesDay, "fib50");
        // BULL TRADES ***********************************
        // FIB BOUNCE ******************************************************
        if (majorDirection === "up" && fib50Bounce === "bull" && ema1daybull ) {
            strTrade="bull T2 Fib 50% 1D";
        }

        fib61Bounce = await priceBounce(pricesDay, "fib61");
        // BULL TRADES ***********************************
        // FIB BOUNCE ******************************************************
        if (majorDirection === "up" && fib61Bounce === "bull" && ema1daybull ) {
            strTrade="bull T2 Fib 61.8% 1D";
        }

        fib76Bounce = await priceBounce(pricesDay, "fib76");
        // BULL TRADES ***********************************
        // FIB BOUNCE ******************************************************
        if (majorDirection === "up" && fib76Bounce === "bull" && ema1daybull ) {
            strTrade="bull T2 Fib 76.4% 1D";
        }

        fib100Bounce = await priceBounce(pricesDay, "fib100" && ema1daybull );
        // BULL TRADES ***********************************
        // FIB BOUNCE ******************************************************
        if (majorDirection === "up" && fib100Bounce === "bull") {
            strTrade="bull T2 Fib 100% 1D";
        }

        // BEAR TRADES *******************************************************************************
        // T1 MAJOR BOUNCE ******************************************************
        if (majorDirection === "down" && trendBounce === "bear" && ema1daybear ) {
            strTrade="bear T1 major 1D";
        }

        if (majorDirection === "down" && trendBounce4h === "bear" && ema4hbear ) {
            strTrade="bear T1 major 1D";
        }

        if (majorDirection === "down" && trendBounce1h === "bear" && ema1hbear ) {
            strTrade="bear T1 major 1D";
        }

        // T2 MINOR BREAK *******************************************************
        if (currDayClose < minorTrend && currDayOpen > minorTrend) {
            strTrade="bear T2 minor 1D";
        }

        // T2 DAILY EMA 10/20 BOUNCE **************************************************
        if (dailyEmaBounce === "N") {
            strTrade="bear T2 1020 1D";
        }

        // T2 4 Hourly EMA 10/20 BOUNCE **************************************************
        if (fourHourlyEmaBounce === "N") {
            strTrade="bear T2 1020 4h";
        }

        // T2 1 Hourly EMA 10/20 BOUNCE **************************************************
        if (hourlyEmaBounce === "N") {
            strTrade="bear T2 1020 1h";
        }
        await updateAssetField(symbol, 'readytradelt', strTrade);

        fib0Bounce = await priceBounce(pricesDay, "fib0");
        // BEAR TRADES ***********************************
        // FIB BOUNCE ******************************************************
        if (majorDirection === "down" && fib0Bounce === "bear" && ema1daybear ) {
            strTrade="bear T2 Fib 0% 1D";
        }

        fib23Bounce = await priceBounce(pricesDay, "fib23");
        // BEAR TRADES ***********************************
        // FIB BOUNCE ******************************************************
        if (majorDirection === "down" && fib23Bounce === "bear" && ema1daybear ) {
            strTrade="bear T2 Fib 23.6% 1D";
        }

        fib38Bounce = await priceBounce(pricesDay, "fib38");
        // BEAR TRADES ***********************************
        // FIB BOUNCE ******************************************************
        if (majorDirection === "down" && fib38Bounce === "bear" && ema1daybear ) {
            strTrade="bear T2 Fib 38.2% 1D";
        }

        fib50Bounce = await priceBounce(pricesDay, "fib50");
        // BEAR TRADES ***********************************
        // FIB BOUNCE ******************************************************
        if (majorDirection === "down" && fib50Bounce === "bear" && ema1daybear ) {
            strTrade="bear T2 Fib 50% 1D";
        }

        fib61Bounce = await priceBounce(pricesDay, "fib61");
        // BEAR TRADES ***********************************
        // FIB BOUNCE ******************************************************
        if (majorDirection === "down" && fib61Bounce === "bear" && ema1daybear ) {
            strTrade="bear T2 Fib 61.8% 1D";
        }

        fib76Bounce = await priceBounce(pricesDay, "fib76");
        // BEAR TRADES ***********************************
        // FIB BOUNCE ******************************************************
        if (majorDirection === "down" && fib76Bounce === "bear" && ema1daybear ) {
            strTrade="bear T2 Fib 76.4% 1D";
        }

        fib100Bounce = await priceBounce(pricesDay, "fib100");
        // BEAR TRADES ***********************************
        // FIB BOUNCE ******************************************************
        if (majorDirection === "down" && fib100Bounce === "bear" && ema1daybear ) {
            strTrade="bear T2 Fib 100% 1D";
        }
    }

}

function bullTrade(strType) {
    //console.log("bull trade?");
    let strTrade = "";
    if (dailyTrend === "bear") {
        strTrade = "bull reaction " + strType;
    }
    if (dailyTrend === "bull") {
        strTrade = "bull retracement " + strType;
    }
    if (strTrade === "") {
        // NOT A TRADE
    } else {
        // 4 hourly check - above last close and above EMAs
        if (close4h > currDayClose && ema4hbull) {
            strTrade = strTrade + " 4h";
        }
        // 1 hour check - above last close and above EMAs
        if (close1h > currDayClose && ema1hbull) {
            strTrade = strTrade + " 1h";
        }
    }
    return strTrade;
}

function bearTrade(strType) {
    //console.log("bear trade?");
    let strTrade = "";
    if (dailyTrend === "bull") {
        strTrade = "bear reaction " + strType;
    }
    if (dailyTrend === "bear") {
        strTrade = "bear retracement " + strType;
    }
    if (strTrade === "") {
        // NOT A TRADE
    } else {

        // 4 hourly check - above last close and above EMAs
        if (close4h < currDayClose && ema4hbear) {
            strTrade = strTrade + " 4h";
        }
        // 1 hour check - above last close and above EMAs
        if (close1h < currDayClose && ema1hbear) {
            strTrade = strTrade + " 1h";
        }
    }
    return strTrade;
} 