//GetExchangeTimes.js

import { openDB } from 'idb';
import { getCurrentVersion } from './db.js';

// Main function to load, process, and save data
export async function getExchangeTimes() {
    try {
        const response = await fetch('/exchangetimes.csv');
        const csvData = await response.text();
        console.log("Exchange times csv ",csvData);
        const processedData = processData(csvData);
        await saveToIndexedDB(processedData);
    } catch (error) {
        console.error('Error occurred:', error);
    }
};

// Helper function to create time slots
const createTimeSlots = () => {
    const slots = {};
    for (let hour = 0; hour < 24; hour++) {
        for (let min = 0; min < 60; min += 30) {
            const time = `${hour.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}`;
            slots[time] = 'closed';
        }
    }
    return slots;
};

// Function to process CSV data
const processData = (csvData) => {
    return csvData.split('\n').map(row => {
        const [code, name, times, times2, timeDifferenceStr] = row.split(',').map(item => item.trim());
        console.log("code ",code,"name ",name,"times ",times,"times2 ",times2,"timedifference ",timeDifferenceStr);
        const timeDifference = parseFloat(timeDifferenceStr);
        const openSlots = createTimeSlots();

        const processTimeRange = (timeRange) => {
            if (!timeRange) return;
            const [start, end] = timeRange.split('-').map(t => t.trim());
            let current = adjustTimeForTimeZone(start, timeDifference);
            const endAdjusted = adjustTimeForTimeZone(end, timeDifference);

            while (current !== endAdjusted) {
                openSlots[current] = 'open';
                current = getNextHalfHourSlot(current);
            }
        };

        if (times) processTimeRange(times);
        if (times2) processTimeRange(times2);

        return { code, name, ...openSlots };
    });
};

// Function to get the next half-hour slot
const getNextHalfHourSlot = (time) => {
    const [hour, minute] = time.split(':').map(Number);
    const nextMinute = minute === 0 ? 30 : 0;
    const nextHour = minute === 0 ? hour : (hour + 1) % 24;
    return `${nextHour.toString().padStart(2, '0')}:${nextMinute.toString().padStart(2, '0')}`;
};

// Function to save data to exchangetimes
const saveToIndexedDB = async (data) => {
    const currentVersion = await getCurrentVersion();
    const db = await openDB('AssetsDatabase', currentVersion);
    const tx = db.transaction('exchangetimes', 'readwrite');
    for (const item of data) {
        tx.store.put(item);
    }
    await tx.done;
    db.close();
};

const adjustTimeForTimeZone = (time, timeDifference) => {
    const [hour, minute] = time.split(':').map(Number);
    let adjustedHour = hour - Math.floor(timeDifference); // Subtract for positive offset
    let adjustedMinute = minute - (timeDifference % 1) * 60; // Subtract for positive offset

    // Adjust for minutes overflow or underflow
    if (adjustedMinute < 0) {
        adjustedHour -= 1;
        adjustedMinute += 60;
    } else if (adjustedMinute >= 60) {
        adjustedHour += 1;
        adjustedMinute -= 60;
    }

    // Wrap around if time goes beyond 24 hours or below 0
    if (adjustedHour < 0) {
        adjustedHour += 24;
    } else if (adjustedHour >= 24) {
        adjustedHour -= 24;
    }

    return `${adjustedHour.toString().padStart(2, '0')}:${adjustedMinute.toString().padStart(2, '0')}`;
};

