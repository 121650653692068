// PlaceTrade.js

import axios from 'axios';
import { fetchAsset } from './db.js';

const IG_API_URL = 'https://api.ig.com/gateway/deal';
const IG_API_KEY = process.env.REACT_APP_IG_API_KEY;
const IG_PASSWORD = process.env.REACT_APP_IG_PASSWORD;
const IG_USERNAME = process.env.REACT_APP_IG_USERNAME;
const IG_ACCOUNT_ID = process.env.REACT_APP_IG_ACCOUNT_ID;

async function createSession() {
    try {
        const response = await axios.post(`${IG_API_URL}/session`, {
            identifier: IG_USERNAME,
            password: IG_PASSWORD
        }, {
            headers: {
                'X-IG-API-KEY': IG_API_KEY,
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'VERSION': 2
            }
        });

        return {
            'X-SECURITY-TOKEN': response.headers['x-security-token'],
            'CST': response.headers['cst']
        };
    } catch (error) {
        console.error('Error creating session:', error);
        throw error;
    }
}

async function getEpic(searchTerm, assetType, expiry = "DFB") {
    try {
        const sessionHeaders = await createSession();
        const searchUrl = `${IG_API_URL}/markets?searchTerm=${searchTerm}`;


        const response = await axios.get(searchUrl, {
            headers: {
                ...sessionHeaders,
                'X-IG-API-KEY': IG_API_KEY,
                'IG-ACCOUNT-ID': IG_ACCOUNT_ID
            }
        });

        // Filter markets based on decoded asset type and expiry
        const filteredMarkets = response.data.markets.filter(market =>
            market.instrumentType === assetType && market.expiry === expiry
        );

        if (filteredMarkets.length > 0) {
            return filteredMarkets[0].epic;  // Return the EPIC of the first matching market
        } else {
            throw new Error(`No market found for ${searchTerm} with type ${assetType} and expiry ${expiry}`);
        }
    } catch (error) {
        console.error(`Error fetching EPIC for ${searchTerm} with type ${assetType} and expiry ${expiry}:`, error);
        return null;
    }
}

export async function getAccountDetails(epic) {
    try {
        const sessionHeaders = await createSession();
        const accountDataUrl = `${IG_API_URL}/accounts`;
        const response = await axios.get(accountDataUrl, {
            headers: {
                ...sessionHeaders,
                'X-IG-API-KEY': IG_API_KEY,
                'IG-ACCOUNT-ID': IG_ACCOUNT_ID
            }
        });

        const account = response.data.accounts.find(acc => acc.accountId === IG_ACCOUNT_ID);

        return account || null;  // return the found account or null if not found

    } catch (error) {
        console.error(`Error fetching account details: `, error);
        return null;
    }
}

export async function getMarketDetails(epic) {
    try {
        const sessionHeaders = await createSession();
        const marketDataUrl = `${IG_API_URL}/markets/${epic}`;
        const response = await axios.get(marketDataUrl, {
            headers: {
                ...sessionHeaders,
                'X-IG-API-KEY': IG_API_KEY,
                'IG-ACCOUNT-ID': IG_ACCOUNT_ID
            }
        });
        return response.data;
    } catch (error) {
        console.error(`Error fetching trading details for ${epic}:`, error);
        return null;
    }
}

export async function setupTrade(assetSymbol) {

    let assetRecord = await fetchAsset(assetSymbol);
    const tradeConditions = assetRecord.tradeconditions;
    const tradeDirection = assetRecord.tradedirection;
    let stopPrice = assetRecord.stopprice;
    const assetTypeCode = assetRecord.assettype;
    if (stopPrice === 0) {
        stopPrice = parseFloat(assetRecord.ema200);
        console.log("Zero Stop Price - please check this is a valid trade - DO NOT TRADE");
    }

    // Decode assetTypeCode
    let assetType;
    switch (assetTypeCode) {
        case 'I':
            assetType = 'INDICES';
            break;
        case 'F':
            assetType = 'CURRENCIES';
            break;
        case 'C':
            assetType = 'COMMODITIES';
            break;
        default:
            throw new Error(`Invalid asset type code: ${assetTypeCode}`);
    }

    let riskLevel;
    switch (tradeConditions) {
        case "AGG":
            riskLevel = 0.01;
            break;
        case "INT":
            riskLevel = 0.015;
            break;
        case "CON":
            riskLevel = 0.02;
            break;
        default:
            throw new Error(`Invalid trade conditions: ${tradeConditions}`);
    }


    const symbol = assetSymbol.replace('/', '');
    const expiry = "DFB"
    const abovers = 100 * assetRecord.abovers;
    const belowrs = 100 * assetRecord.belowrs;
    const epic = await getEpic(symbol, assetType, expiry);

    const marketDetails = await getMarketDetails(epic, assetType);
    console.log("MARKET DETAILS:");
    console.log(marketDetails);
    const accountDetails = await getAccountDetails(epic, assetType);
    console.log("ACCOUNT DETAILS:");
    console.log(accountDetails);
    const accountAvailable = accountDetails.balance.available;
    const accountBalance = accountDetails.balance.balance;
    const bidPrice = marketDetails.snapshot.bid;
    const offerPrice = marketDetails.snapshot.offer;
    const tradePrice = tradeDirection === "bull" ? offerPrice : bidPrice;
    const minStopSize = marketDetails.dealingRules.minNormalStopOrLimitDistance.value;
    const minDealSize = marketDetails.dealingRules.minDealSize.value;

    const stopDistance = await calculateStopDistance(epic, stopPrice, tradePrice, minStopSize);
    const maxTradeSize = await calculateMaxTradeSize(stopDistance, tradeConditions, accountBalance, riskLevel);
    const riskRatio = calculateRewardToRiskRatio(tradePrice, stopPrice, tradeDirection, abovers, belowrs);

    const tradeSize = calculateTradeSize(accountBalance, riskLevel, tradePrice, stopPrice);
    const tradeMargin = calculateMargin(tradeSize, marketDetails, stopDistance, tradeConditions);

    console.log("deal Details", "Epic ", epic, "Trade Size ", tradeSize, "Stop Price ", stopPrice, "Trade Direction ", tradeDirection, "Risk Ratio ", riskRatio, "Trade Conditions ", tradeConditions);

    if ((riskRatio > 8 && tradeConditions === "AGG") || (riskRatio > 5 && tradeConditions === "INT") || (riskRatio > 3 && tradeConditions === "CON")) {
        if (maxTradeSize > minDealSize && tradeMargin < accountAvailable) {

            // CODE HER TO DISPLAY POSSIBLE TRADE BEFORE PLACING TRADE
            console.log("Deal Executable");
                        
            // PLACEHOLDER DEAL PLACE CALL***********************************************************************
            if(true===false){
                const dealDetails = await placeTrade(epic, tradeSize, stopPrice, tradeDirection);
                console.log(dealDetails);
            }

        }
    }
    return;
}

function calculateTradeSize(accountBalance, riskLevel, currentPrice, stopPrice) {

    // Calculate the maximum amount you can risk on this trade
    const maxRiskAmount = accountBalance * riskLevel;

    // Calculate the stop distance
    const stopDistance = Math.abs(currentPrice - stopPrice);

    // Assuming point value is known or calculated separately. Replace with actual calculation or value.
    const pointValue = 1; // Replace this with the actual point value calculation

    // Check if stopDistance is non-zero to avoid division by zero
    if (stopDistance <= 0) {
        throw new Error("Invalid stop distance: Stop distance should be greater than zero");
    }

    // Calculate the trade size
    const tradeSize = maxRiskAmount / (pointValue * stopDistance);

    return tradeSize;
}

async function calculateMargin(tradeSize, marketDetails, stopDistance, tradeConditions) {
    const marketPrice = tradeConditions === "bull" ? marketDetails.snapshot.offer : marketDetails.snapshot.bid;
    const marginFactor = marketDetails.marginFactor; // Replace with actual field name from API response
    const slippageFactor = marketDetails.slippageFactor; // Replace with actual field name from API response

    let margin;
    if (stopDistance > 0) {
        // With non-guaranteed stop
        margin = (tradeSize * marketPrice * slippageFactor * marginFactor) + (tradeSize * stopDistance);
    } else {
        // Without stop
        margin = tradeSize * marketPrice * marginFactor;
    }

    return margin;
}


async function placeTrade(epic, tradeSize, stopPrice, tradeDirection) {
    const sessionHeaders = await createSession();
    const placeTradeUrl = `${IG_API_URL}/positions/otc`;

    const direction = tradeDirection === "bull" ? "BUY" : "SELL";
    const tradeData = {
        epic,
        size: tradeSize, // This is the calculated trade size
        direction,
        orderType: "MARKET",
        stopDistance: calculateStopDistance(stopPrice) // Implement this based on your logic
        // Add other necessary parameters
    };

    try {
        const response = await axios.post(placeTradeUrl, tradeData, {
            headers: {
                ...sessionHeaders,
                'X-IG-API-KEY': IG_API_KEY,
                'IG-ACCOUNT-ID': IG_ACCOUNT_ID
            }
        });
        return response.data;
    } catch (error) {
        console.error(`Error placing trade: ${error}`);
        return null;
    }
}

export async function moveStopLevel(dealId, newStopLevel) {
    try {
        const sessionHeaders = await createSession();
        const updatePositionUrl = `${IG_API_URL}/positions/otc/${dealId}`;

        const response = await axios.put(updatePositionUrl, {
            stopLevel: newStopLevel
        }, {
            headers: {
                ...sessionHeaders,
                'X-IG-API-KEY': IG_API_KEY,
                'IG-ACCOUNT-ID': IG_ACCOUNT_ID
            }
        });

        return response.data;
    } catch (error) {
        console.error(`Error moving stop level: ${error}`);
        return null;
    }
}

export async function closeTrade(dealId) {
    try {
        const sessionHeaders = await createSession();
        const closePositionUrl = `${IG_API_URL}/positions/otc/${dealId}`;

        const response = await axios.delete(closePositionUrl, {
            headers: {
                ...sessionHeaders,
                'X-IG-API-KEY': IG_API_KEY,
                'IG-ACCOUNT-ID': IG_ACCOUNT_ID
            }
        });

        return response.data;
    } catch (error) {
        console.error(`Error closing trade: ${error}`);
        return null;
    }
}

async function calculateMaxTradeSize(stopDistance, tradeConditions, accountBalance, riskLevel) {

    const maxRiskAmount = accountBalance * riskLevel;

    // Check if stopDistance is non-zero to avoid division by zero
    if (stopDistance <= 0) {
        throw new Error("Invalid stop distance: Stop distance should be greater than zero");
    }

    const maxTradeSize = maxRiskAmount / stopDistance;

    return maxTradeSize;
}

async function calculateStopDistance(epic, stopPrice, tradePrice, minStopSize) {
    try {


        let stopDistance = Math.abs(tradePrice - stopPrice);


        if (stopDistance <= 0) {
            throw new Error("Invalid stopPrice: Stop price should be different from the current price");
        }

        // Adjust the stop distance to meet the minimum requirement if necessary
        if (stopDistance < minStopSize) {
            stopDistance = minStopSize;
        }

        return stopDistance;
    } catch (error) {
        console.error(`Error calculating stop distance for ${epic}: ${error}`);
        return null;
    }
}

function calculateRewardToRiskRatio(tradePrice, stopPrice, tradeDirection, abovers, belowrs) {
    let targetPrice;
    if (tradeDirection === "bull") {
        targetPrice = abovers; // Target price for bull trades
    } else if (tradeDirection === "bear") {
        targetPrice = belowrs; // Target price for bear trades
    } else {
        throw new Error(`Invalid trade direction: ${tradeDirection}`);
    }

    const potentialProfit = Math.abs(targetPrice - tradePrice);
    const potentialLoss = Math.abs(stopPrice - tradePrice);

    // Check for division by zero
    if (potentialLoss === 0) {
        throw new Error("Invalid stop price: Potential loss cannot be zero");
    }

    const riskRatio = potentialProfit / potentialLoss;

    return riskRatio;
}
