// AssetFunctions.js

import { openDB } from 'idb';
import { getCurrentVersion } from './db.js';

export async function addAssets() {
  try {
 
    console.log("Step 1: Import asset data from symbols.csv");
    const response = await fetch('/symbols.csv');
    const text = await response.text();
    // Split the text by new lines and then by commas, filter out any empty lines
    let assetsArray = text.split('\n').map(line =>
      line.trim().split(',').map(field => field.trim())
    ).filter(line => line.length > 1);  // Ensure to filter out any line that does not have the expected number of fields

    console.log("Step 2: Process each asset in the array");
    const currentVersion = await getCurrentVersion();
    const db = await openDB('AssetsDatabase', currentVersion);
    const tx = db.transaction('assets', 'readwrite');
    const store = tx.objectStore('assets');

    for (const assetFields of assetsArray) {
      // Destructure your fields from the assetFields array
      const [symbol, assettype, majordirection, majordate1, majordate2, minordirection, minordate1, minordate2, rs1, rs2, rs3, rs4, rs5, rs6, rs7, rs8, rs9, rs10, fibhighdate, fiblowdate, cleartrend] = assetFields;
      const formattedMajordate1 = convertDateFormat(majordate1);
      const formattedMajordate2 = convertDateFormat(majordate2);
      const formattedMinordate1 = convertDateFormat(minordate1);
      const formattedMinordate2 = convertDateFormat(minordate2);
      const formattedfibhighdate = convertDateFormat(fibhighdate);
      const formattedfiblowdate = convertDateFormat(fiblowdate);
      console.log("Asset processing: ", symbol);
      // try {
      const updatedAsset = {
        symbol,
        assettype,
        updated: new Date().toISOString(),
        monthlytrend: '',
        weeklytrend: '',
        dailytrend: '',
        fourhourlytrend: '',
        hourlytrend: '',
        fifteenminutelytrend: '',
        minutelytrend: '',
        monthlyemastack: '',
        weeklyemastack: '',
        dailyemastack: '',
        fourhourlyemastack: '',
        hourlyemastack: '',
        fifteenminutelyemastack: '',
        minutelyemastack: '',
        monthlyemabounce: '',
        weeklyemabounce: '',
        dailyemabounce: '',
        fourhourlyemabounce: '',
        hourlyemabounce: '',
        fifteenminutelyemabounce: '',
        minutelyemabounce: '',
        daily200bounce: '',
        fourhourly200bounce: '',
        hourly200bounce: '',
        fifteenminutely200bounce: '',
        minutely200bounce: '',
        daily100bounce: '',
        fourhourly100bounce: '',
        hourly100bounce: '',
        fifteenminutely100bounce: '',
        minutely100bounce: '',
        daily50bounce: '',
        fourhourly50bounce: '',
        hourly50bounce: '',
        fifteenminutely50bounce: '',
        minutely50bounce: '',
        dailyatr: '',
        averagedailyatr: '',
        fourhourlyatr: '',
        dailypivot: '',
        dailypivots1: '',
        dailypivots2: '',
        dailypivotr1: '',
        dailypivotr2: '',
        fourhourlypivot: '',
        fourhourlypivots1: '',
        fourhourlypivots2: '',
        fourhourlypivotr1: '',
        fourhourlypivotr2: '',
        dailyema200: '',
        fourhourlyema200: '',
        dailymajor: '',
        dailyminor: '',
        lastclose: '',
        lastdayclose: '',
        analysis: '',
        tradealert: '',
        tradedirection: '',
        stoplevel: '',
        tradesize: '',
        tradeprice: '',
        headroom: '',
        floorroom: '',
        headroomatrratio: '',
        floorroomatrratio: '',
        abovers: '',
        belowrs: '',
        readytradelt: '',
        readytradeet: '',
        superearlyadopter: '',
        earlyadopter: '',
        earlyadopterpp: '',
        lazytraderema: '',
        t1major: '',
        t2minor: '',
        t2ema1020: '',
        t2ema10204h: '',
        t2ema10201h: '',
        stopprice: '',
        tradeconditions: '',
        riskfloorcon: '',
        riskfloorint: '',
        riskflooragg: '',
        riskheadcon: '',
        riskheadint: '',
        riskheadagg: '',
        ath: '',
        atl: '',
        sth: '',
        stl: '',
        majordirection,
        majordate1: formattedMajordate1,
        majordate2: formattedMajordate2,
        majorprice1: '',
        majorprice2: '',
        minordirection,
        minordate1: formattedMinordate1,
        minordate2: formattedMinordate2,
        minorprice1: '',
        minorprice2: '',
        fibhighdate: formattedfibhighdate,
        fiblowdate: formattedfiblowdate,
        fibhighprice: '',
        fiblowprice: '',
        fib100: '',
        fib76: '',
        fib61: '',
        fib50: '',
        fib38: '',
        fib23: '',
        fib0: '',
        cleartrend,
        rs1,
        rs2,
        rs3,
        rs4,
        rs5,
        rs6,
        rs7,
        rs8,
        rs9,
        rs10,
        rs11: '',
        rs12: '',
        rs13: '',
        rs14: '',
        rs15: '',
        rs16: '',
        rs17: '',
        rs18: '',
        rs19: '',
        rs20: '',
        bgStatus: ''
      };

      // Check if the asset already exists
      const existingAsset = await store.get(symbol); // Use symbol to get the existing asset
      if (!existingAsset) {
        await store.add(updatedAsset);
      } else {
        // if exists, update the record
        await store.put(updatedAsset);
      }
      console.log(`Asset ${symbol} processed successfully.`);
      // } catch (error) {
      //  console.error(`Error processing asset ${symbol}:`, error);
      // }
    }
    await tx.done;
    db.close(); // Ensure DB is always closed
  } catch (error) {
    console.error(error.message);
  }
};

function convertDateFormat(dateString) {
  const parts = dateString.split('/');
  if (parts.length === 3) {
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  }
  return dateString; // return original string if it's not in expected format
}

