// WeeklyForex.js

import { openDB } from 'idb';
import Papa from 'papaparse';
import { getCurrentVersion } from './db.js';

export async function processCsvData(csvContent) {
  console.log("csv content: ",csvContent);
  const parsedData = Papa.parse(csvContent, { header: true, dynamicTyping: true, skipEmptyLines: true });
  const currentVersion = await getCurrentVersion();
  const db = await openDB('AssetsDatabase', currentVersion);

  const tx = db.transaction('forexdates', 'readwrite');
  const store = tx.objectStore('forexdates');

  parsedData.data.forEach(row => {
    // Process 'Date' field
    if (row.Date) {
      const dateParts = row.Date.split('-'); // Assuming the format is mm-dd-yyyy
      // Format date as a string "yyyy-mm-dd"
      row.Date = `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`; // Reassign as string in yyyy-mm-dd format
    }
    //if (row.Date) {
    //  const dateParts = row.Date.split('-'); // Assuming the format is mm-dd-yyyy
    //  row.Date = new Date(`${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`); // Convert to yyyy-mm-dd format
    //}
    
    // Process 'Time' field
    if (row.Time) {
      const timeString = row.Time.endsWith('am') || row.Time.endsWith('pm') ? row.Time : row.Time + 'am';
      const timeParts = timeString.match(/(\d+):(\d+)(am|pm)/);
      let hours = parseInt(timeParts[1]);
      const minutes = timeParts[2];
      const ampm = timeParts[3];
  
      if (ampm === 'pm' && hours < 12) hours += 12;
      if (ampm === 'am' && hours === 12) hours = 0; // Convert 12 am to 00 hours
  
      row.Time = `${hours.toString().padStart(2, '0')}:${minutes}`; // Convert to 24-hour hh:mm format
    }
  
    const updatedAsset = {
      title: row.Title,
      country: row.Country,
      date: row.Date,
      time: row.Time,
      impact: row.Impact,
      forecast: row.Forecast,
      previous: row.Previous
    };
  
    store.put(updatedAsset); // Update the row in IndexedDB
  });

  await tx.done;
  db.close();

}
