import React, { useState, useEffect, useRef } from 'react';
import { Rnd } from 'react-rnd';
import FinancialChart from './FinancialChart';

const ChartModal = ({ isOpen, onRequestClose, symbol }) => {
  const [selectedInterval, setSelectedInterval] = useState("1day");
  const intervals = ["1month", "1week", "1day", "4h", "1h", "15min", "1min"];
  const chartContainerRef = useRef(null);
  const [chartDimensions, setChartDimensions] = useState({ width: 1600, height: 1000 });
  const nonChartElementsHeight = 50; // Adjust this value based on your actual UI
  
  useEffect(() => {
    if (chartContainerRef.current) {
      const { width, height } = chartContainerRef.current.getBoundingClientRect();
      setChartDimensions({ width, height });
    }
  }, [chartContainerRef]);

  if (!isOpen) return null;

  return (
    <Rnd
      default={{
        x: 20, // Set 20 pixels from the left edge of the window
        y: 20, // Set 20 pixels from the top edge of the window
        width: window.innerWidth * 0.9, // Adjusted width by 20% as previously discussed
        height: window.innerHeight * 0.95 - nonChartElementsHeight, // Adjusted height by 20% and for non-chart elements
      }}
      bounds="window"
      onResize={(e, direction, ref) => {
        setChartDimensions({
          width: ref.offsetWidth,
          height: ref.offsetHeight,
        });
      }}
      disableDragging // Disable the dragging functionality
    >
      <div ref={chartContainerRef} style={{ 
        width: '100%', 
        height: '100%',
        backgroundColor: 'white',
        border: '2px solid #ccc'
        }}>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <div style={{ marginBottom: '10px' }}>
            {intervals.map(interval => (
              <button key={interval} onClick={() => setSelectedInterval(interval)}>
                {interval}
              </button>
            ))}
            <button onClick={onRequestClose}>Close</button>
          </div>
          <div style={{ flexGrow: 1, height: 'calc(100% - 40px)' }}>
            <FinancialChart 
              symbol={symbol} 
              interval={selectedInterval}
              width={chartDimensions.width}
              height={chartDimensions.height - 40} // Adjust for button area
            />
          </div>
        </div>
      </div>
    </Rnd>
  );
};

export default ChartModal;
