// FinancialChart.js

import React, { useState, useEffect } from 'react';
import { format } from "d3-format";
import { timeFormat } from 'd3-time-format';
import { openDB } from 'idb';
import { getCurrentVersion, fetchValue } from './db';
import {
  discontinuousTimeScaleProviderBuilder,
  Chart,
  ChartCanvas,
  CandlestickSeries,
  OHLCSeries,
  OHLCTooltip,
  lastVisibleItemBasedZoomAnchor,
  XAxis,
  YAxis,
  CrossHairCursor,
  EdgeIndicator,
  MouseCoordinateX,
  MouseCoordinateY,
  ZoomButtons,
  LineSeries,
  ema,
  MovingAverageTooltip,
  SingleValueTooltip
} from "react-financial-charts";

function transformData(initialData, pp, rs1, rs2, rs3, rs4, rs5, rs6, rs7, rs8, rs9, rs10) {
  return initialData.map(item => ({
    date: item.date,
    open: parseFloat(item.open),
    low: parseFloat(item.low),
    high: parseFloat(item.high),
    close: parseFloat(item.close),
    volume: parseInt(item.volume, 10),
    ema10: parseFloat(item.ema10),
    ema20: parseFloat(item.ema20),
    ema50: parseFloat(item.ema50),
    ema100: parseFloat(item.ema100),
    ema200: parseFloat(item.ema200),
    atr: parseFloat(item.atr),
    major: parseFloat(item.major),
    minor: parseFloat(item.minor),
    pp: parseFloat(item.pp),
    rs1: parseFloat(item.rs1),
    rs2: parseFloat(item.rs2),
    rs3: parseFloat(item.rs3),
    rs4: parseFloat(item.rs4),
    rs5: parseFloat(item.rs5),
    rs6: parseFloat(item.rs6),
    rs7: parseFloat(item.rs7),
    rs8: parseFloat(item.rs8),
    rs9: parseFloat(item.rs9),
    rs10: parseFloat(item.rs10)
  }));
}

async function fetchData(tableName, pp, rs1, rs2, rs3, rs4, rs5, rs6, rs7, rs8, rs9, rs10) {
  const currentVersion = await getCurrentVersion();
  const db = await openDB('AssetsDatabase', currentVersion);
  const transaction = db.transaction(tableName, 'readonly');
  const objectStore = transaction.objectStore(tableName);
  const allRecords = await objectStore.getAll();
  db.close();
  return transformData(allRecords, pp, rs1, rs2, rs3, rs4, rs5, rs6, rs7, rs8, rs9, rs10);
}

const FinancialChart = ({ symbol, interval, width, height }) => {

  const [chartData, setChartData] = useState(null);
  const [error, setError] = useState(null);
  const [barType, setBarType] = useState('candlestick');
  const tableName = `${symbol.replace('/', '_')}-${interval}`;

  const [pivotPoints, setPivotPoints] = useState({
    pp: null,
    rs1: null,
    rs2: null,
    rs3: null,
    rs4: null,
    rs5: null,
    rs6: null,
    rs7: null,
    rs8: null,
    rs9: null,
    rs10: null
  });


  const toggleBarType = () => {
    setBarType(prevType => (prevType === 'candlestick' ? 'hloc' : 'candlestick'));
  };

  useEffect(() => {
    // Fetch pivot points only once on mount or when the symbol/interval changes.
    const fetchPivotPoints = async () => {
      try {
        const ppValue = await fetchValue(symbol, "dailypivot");
        const rs1Value = await fetchValue(symbol, "rs1");
        const rs2Value = await fetchValue(symbol, "rs2");
        const rs3Value = await fetchValue(symbol, "rs3");
        const rs4Value = await fetchValue(symbol, "rs4");
        const rs5Value = await fetchValue(symbol, "rs5");
        const rs6Value = await fetchValue(symbol, "rs6");
        const rs7Value = await fetchValue(symbol, "rs7");
        const rs8Value = await fetchValue(symbol, "rs8");
        const rs9Value = await fetchValue(symbol, "rs9");
        const rs10Value = await fetchValue(symbol, "rs10");

        // Update state only if all values are fetched
        if (ppValue && rs1Value && rs2Value && rs3Value && rs4Value && rs5Value && rs6Value && rs7Value && rs8Value && rs9Value && rs10Value) {
          setPivotPoints({
            pp: ppValue,
            rs1: rs1Value,
            rs2: rs2Value,
            rs3: rs3Value,
            rs4: rs4Value,
            rs5: rs5Value,
            rs6: rs6Value,
            rs7: rs7Value,
            rs8: rs8Value,
            rs9: rs9Value,
            rs10: rs10Value
          });
        }
      } catch (fetchError) {
        console.error("Error fetching pivot points:", fetchError);
        setError(fetchError.message);
      }
    };

    fetchPivotPoints();
  }, [symbol, interval]); // Add symbol and interval to the dependency array if needed.

  useEffect(() => {
    // Define a separate effect for fetching and setting chart data.
    const fetchAndSetData = async () => {
      // Destructure the current state to get pivot point values.
      const { pp, rs1, rs2, rs3, rs4, rs5, rs6, rs7, rs8, rs9, rs10 } = pivotPoints;
      // Check if all pivot points are available.
      if (pp !== null && rs1 !== null && rs2 !== null && rs3 !== null && rs4 !== null && rs5 !== null && rs6 !== null && rs7 !== null && rs8 !== null && rs9 !== null && rs10 !== null) {
        try {
          const data = await fetchData(tableName, pp, rs1, rs2, rs3, rs4, rs5, rs6, rs7, rs8, rs9, rs10);
          //console.log("Data ", data);
          if (data.length === 0) {
            console.error(`No data found for table: ${tableName}`);
            setError(`No data found for table: ${tableName}`);
          } else {
            setChartData(data); // Set chart data if available
          }
        } catch (error) {
          console.error(error);
          setError(error.message);
        }
      }
    };

    // Call fetchAndSetData when pivotPoints state changes and all values are available.
    fetchAndSetData();
  }, [tableName, pivotPoints]); // Depend on individual pivot point values instead of the object to avoid unnecessary effect runs.



  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!chartData) {
    return <div>Loading...</div>;
  }

  return renderChart(chartData, barType, toggleBarType, tableName, pivotPoints, width, height);
};

const ema10 = ema()
  .id(0)
  .options({ windowSize: 10 })
  .merge((d, c) => { d.ema10 = c; })
  .accessor(d => d.ema10);

const ema20 = ema()
  .id(1)
  .options({ windowSize: 20 })
  .merge((d, c) => { d.ema20 = c; })
  .accessor(d => d.ema20);

const ema50 = ema()
  .id(2)
  .options({ windowSize: 50 })
  .merge((d, c) => { d.ema50 = c; })
  .accessor(d => d.ema50);

const ema100 = ema()
  .id(3)
  .options({ windowSize: 100 })
  .merge((d, c) => { d.ema100 = c; })
  .accessor(d => d.ema100);

const ema200 = ema()
  .id(4)
  .options({ windowSize: 200 })
  .merge((d, c) => { d.ema200 = c; })
  .accessor(d => d.ema200);

/*
  const mouseEdgeAppearance = {
  // Define the appearance properties here; for example:
  strokeStyle: "#0F0F0F",
  strokeWidth: 1,
  fill: "white",
  textBox: {
    height: 20,
    left: -50,
    padding: { left: 10, right: 5 },
  },
  // You can add more properties according to your styling needs
};

*/

function renderChart(datatorender, barType, toggleBarType, tableName, pivotPoints, width, height) {
  const ScaleProvider = discontinuousTimeScaleProviderBuilder().inputDateAccessor(
    (d) => new Date(d.date)
  );
 // Calculate dynamic chart dimensions based on passed width and height
 const margin = { left: 0, right: 50, top: 30, bottom: 30 };
 const gridHeight = height - margin.top - margin.bottom;
 const atrChartHeight = gridHeight * 0.2;
 const chartHeight = gridHeight - atrChartHeight;

  const { data, xScale, xAccessor, displayXAccessor } = ScaleProvider(
    datatorender
  );
  const pricesDisplayFormat = format(".4f");
  const max = xAccessor(data[data.length - 1]);
  const min = xAccessor(data[Math.max(0, data.length - 100)]);
  const xExtents = [min, max];

  // Date formatting function for the x-coordinate tooltip
 const dateTimeFormat = "%Y-%m-%d";
 const dateFormat = timeFormat(dateTimeFormat);

  const lineAccessorPP = () => pivotPoints.pp;
  const lineAccessorRS1 = () => pivotPoints.rs1;
  const lineAccessorRS2 = () => pivotPoints.rs2;
  const lineAccessorRS3 = () => pivotPoints.rs3;
  const lineAccessorRS4 = () => pivotPoints.rs4;
  const lineAccessorRS5 = () => pivotPoints.rs5;
  const lineAccessorRS6 = () => pivotPoints.rs6;
  const lineAccessorRS7 = () => pivotPoints.rs7;
  const lineAccessorRS8 = () => pivotPoints.rs8;
  const lineAccessorRS9 = () => pivotPoints.rs9;
  const lineAccessorRS10 = () => pivotPoints.rs10;

  const candleChartExtents = (d) => {
    return [d.high, d.low];
  };

  const yEdgeIndicator = (d) => {
    return d.close;
  };

  const openCloseColor = (d) => {
    return d.close > d.open ? "#26a69a" : "#ef5350";
  };

  const priceTooltipContent = ({ currentItem, xAccessor }) => {
    return {
      x: format("%Y-%m-%d")(xAccessor(currentItem)),
      y: [
        { label: "Open", value: currentItem.open && format(".2f")(currentItem.open) },
        { label: "Close", value: currentItem.close && format(".2f")(currentItem.close) },
        { label: "High", value: currentItem.high && format(".2f")(currentItem.high) },
        { label: "Low", value: currentItem.low && format(".2f")(currentItem.low) },
      ].filter(line => line.value)
    };
  };

  //console.log("xExtents min value:", min);
  //console.log("xExtents max value:", max);
  return (
    <div>
      <button onClick={toggleBarType}>
        Toggle to {barType === 'candlestick' ? 'HLOC' : 'Candlestick'}
      </button>

      <ChartCanvas
        height={height}
        ratio={3}
        width={width}
        margin={margin}
        data={data}
        displayXAccessor={displayXAccessor}
        seriesName="Data"
        xScale={xScale}
        xAccessor={xAccessor}
        xExtents={xExtents}
        zoomAnchor={lastVisibleItemBasedZoomAnchor}
      >
        {/*
        <Chart
          id={2}
          height={barChartHeight}
          origin={barChartOrigin}
          yExtents={barChartExtents}
        >
          <BarSeries fillStyle={volumeColor} yAccessor={volumeSeries} />
        </Chart>
  */}
        <Chart id={3} height={chartHeight} yExtents={candleChartExtents}>
          <YAxis showGridLines tickFormat={pricesDisplayFormat} />
          {barType === 'candlestick' ? (
            <CandlestickSeries />
          ) : (
            <OHLCSeries stroke={{ up: "green", down: "red" }} />
          )}
          <LineSeries yAccessor={d => d.major} strokeStyle={"black"} strokeWidth={2} />
          <LineSeries yAccessor={d => d.minor} strokeStyle={"black"} strokeWidth={2} />
          <LineSeries yAccessor={ema10.accessor()} strokeStyle={ema10.stroke()} />
          <LineSeries yAccessor={ema20.accessor()} strokeStyle={ema20.stroke()} />
          <LineSeries yAccessor={ema50.accessor()} strokeStyle={ema50.stroke()} />
          <LineSeries yAccessor={ema100.accessor()} strokeStyle={ema100.stroke()} />
          <LineSeries yAccessor={ema200.accessor()} strokeStyle={ema200.stroke()} />
          <MouseCoordinateY
            rectWidth={margin.right}
            displayFormat={pricesDisplayFormat}
          />
          <EdgeIndicator
            itemType="last"
            rectWidth={margin.right}
            fill={openCloseColor}
            lineStroke={openCloseColor}
            displayFormat={pricesDisplayFormat}
            yAccessor={yEdgeIndicator}
          />
          {/* Insert LineSeries for pivot points and resistance lines */}
          <LineSeries yAccessor={lineAccessorRS1} strokeStyle={"blue"} strokeWidth={1} />
          <LineSeries yAccessor={lineAccessorRS2} strokeStyle={"purple"} strokeWidth={1} />
          <LineSeries yAccessor={lineAccessorRS3} strokeStyle={"orange"} strokeWidth={1} />
          <LineSeries yAccessor={lineAccessorRS4} strokeStyle={"red"} strokeWidth={1} />
          <LineSeries yAccessor={lineAccessorRS5} strokeStyle={"blue"} strokeWidth={1} />
          <LineSeries yAccessor={lineAccessorRS6} strokeStyle={"purple"} strokeWidth={1} />
          <LineSeries yAccessor={lineAccessorRS7} strokeStyle={"orange"} strokeWidth={1} />
          <LineSeries yAccessor={lineAccessorRS8} strokeStyle={"pink"} strokeWidth={1} />
          <LineSeries yAccessor={lineAccessorRS9} strokeStyle={"red"} strokeWidth={1} />
          <LineSeries yAccessor={lineAccessorRS10} strokeStyle={"green"} strokeWidth={1} />
          <LineSeries yAccessor={lineAccessorPP} strokeStyle={"yellow"} strokeWidth={1} strokeDasharray="LongDash" />
          <MouseCoordinateX
            at="bottom"
            orient="bottom"
            displayFormat={dateFormat}
          />
          <OHLCTooltip forChart={3} origin={[8, 16]} content={priceTooltipContent} />
          <MovingAverageTooltip
            origin={[8, 24]}
            options={[
              {
                yAccessor: ema10.accessor(),
                type: "EMA",
                stroke: ema10.stroke(),
                windowSize: ema10.options().windowSize,
              },
              {
                yAccessor: ema20.accessor(),
                type: "EMA",
                stroke: ema20.stroke(),
                windowSize: ema20.options().windowSize,
              },
              {
                yAccessor: ema50.accessor(),
                type: "EMA",
                stroke: ema50.stroke(),
                windowSize: ema50.options().windowSize,
              },
              {
                yAccessor: ema100.accessor(),
                type: "EMA",
                stroke: ema100.stroke(),
                windowSize: ema100.options().windowSize,
              },
              {
                yAccessor: ema200.accessor(),
                type: "EMA",
                stroke: ema200.stroke(),
                windowSize: ema200.options().windowSize,
              },
            ]}
          />
          <ZoomButtons />
          <label className="chart-title">{tableName}</label>  {/* New label for chart title */}
        </Chart>
        <Chart id={4} height={chartHeight} yExtents={d => [0, d.atr]}>
          <LineSeries yAccessor={d => d.atr} strokeStyle={openCloseColor} />
        </Chart>

        <Chart
          id={5}
          height={atrChartHeight}
          yExtents={d => [0, d.atr * 1.5]}
          origin={(w, h) => [0, h - atrChartHeight - margin.bottom]}
        >
          <XAxis showGridLines gridLinesStrokeStyle="#e0e3eb" />
          <YAxis orient="right" tickFormat={format(".2f")} />
          <MouseCoordinateX
            at="bottom"
            orient="bottom"
            displayFormat={dateFormat}
          />
         <MouseCoordinateY
            at="right"
            orient="right"
            displayFormat={format(".2f")}
            rectWidth={margin.right}
            dx={0.5}
          />
          <LineSeries yAccessor={d => d.atr} strokeStyle="#4682B4" />
          <SingleValueTooltip
            yAccessor={d => d.atr}
            yLabel="ATR"
            yDisplayFormat={format(".2f")}
            origin={[width - margin.right - 80, 15]}
          />
        </Chart>
      <CrossHairCursor />
    </ChartCanvas>
    </div >

  );
};
export default FinancialChart;
