// TrendLines.js

import { openDB } from 'idb';
import { getAssets, getCurrentVersion, fetchValue } from './db.js';
const defaultIntervals = ["1month", "1week", "1day", "4h", "1h", "15min", "1min"];

export async function trendLines(assetSymbol = "", intervals = "") {
    console.log("Trend lines");
    // Determine the assets and intervals to use based on the parameters
    const assets = assetSymbol ? (typeof assetSymbol === 'string' ? [{ symbol: assetSymbol }] : assetSymbol) : await getAssets();
    const effectiveIntervals = intervals && intervals.length ? intervals : defaultIntervals;
    for (const symbol of assets) {
        // get the trend data points from the assets table
        const majorDirection = await fetchValue(symbol, "majordirection");
        const majorDate1 = await fetchValue(symbol, "majordate1");
        const majorDate2 = await fetchValue(symbol, "majordate2");
        const majorPrice1 = await getTrendValue(symbol, majorDate1, majorDirection);
        const majorPrice2 = await getTrendValue(symbol, majorDate2, majorDirection);
        const minorDirection = await fetchValue(symbol, "minordirection");
        const minorDate1 = await fetchValue(symbol, "minordate1");
        const minorDate2 = await fetchValue(symbol, "minordate2");
        const minorPrice1 = await getTrendValue(symbol, minorDate1, minorDirection);
        const minorPrice2 = await getTrendValue(symbol, minorDate2, minorDirection);

        //console.log("Trend lines: ", symbol);
        for (const interval of effectiveIntervals) {
            const tableName = `${symbol.replace('/', '_')}-${interval}`;
            try {
                // call the function to extrapolate all data points for the major and minor trend lines 
                await populateTrend(tableName, majorDate1, majorPrice1, majorDate2, majorPrice2, "major");
                await populateTrend(tableName, minorDate1, minorPrice1, minorDate2, minorPrice2, "minor");
            } catch (error) {
                console.error(error);
                return null;
            }
        }
    }
}

async function getTrendValue(symbol, trendDate, trendDirection) {
    const dailyTableName = `${symbol.replace('/', '_')}-1day`;
    const currentVersion = await getCurrentVersion();
    const db = await openDB('AssetsDatabase', currentVersion);
    const transaction = db.transaction(dailyTableName, 'readonly');
    const objectStore = transaction.objectStore(dailyTableName);

    const record = await objectStore.get(trendDate);
    let trendPrice = 0;

    if (record) {
        if (trendDirection === "up") {
            trendPrice = parseFloat(record.low);
        } else if (trendDirection === "down") {
            trendPrice = parseFloat(record.high);
        }
    } else {
        console.log("No data found for the given date");
    }

    db.close();
    return trendPrice;
}


async function populateTrend(table, date1, price1, date2, price2, field) {
    const db = await openDB('AssetsDatabase', await getCurrentVersion());
    const tx = db.transaction(table, 'readwrite');
    const store = tx.objectStore(table);
    //console.log("Processing ", field, " for ", table);
    //Normalise date 1 and date2 to include time
    let baseDate = new Date(date1 + ' 00:00:00'); // Normalize base date to include time
    let endDate = new Date(date2 + ' 00:00:00'); // Normalize base date to include time


    // Calculate the slope of the line between the two points
    const dayDiff = (endDate - baseDate) / (24 * 60 * 60 * 1000);
    const priceDiff = parseFloat(price2) - parseFloat(price1);
    const slope = priceDiff / dayDiff;
    //console.log("Slope Data: day diff ", dayDiff, "price diff ", priceDiff, "slope ", slope);

    // Retrieve all records from the store
    let cursor = await store.openCursor();

    while (cursor) {
        // Check if field exists and is a number
        if (cursor.value[field] === 0 || cursor.value[field] === "0") {
            //console.log("Type is string and value of ",field,"should be blank and is ",cursor.value[field]);
            // Use the record's date to calculate the price
            // Adjust for records where the date may be only "yyyy-mm-dd"
            let recordDateStr = cursor.value.date.length === 10 ? cursor.value.date + ' 00:00:00' : cursor.value.date;
            let recordDate = new Date(recordDateStr);

            // Calculate the number of days from the base date to the record's date
            let daysFromBase = (recordDate - baseDate) / (24 * 60 * 60 * 1000);

            // Extrapolate the price for this date using the slope
            let extrapolatedPrice = parseFloat(price1) + slope * daysFromBase;

            // If the extrapolated price is less than zero, set it to zero
            extrapolatedPrice = extrapolatedPrice < 0 ? 0 : extrapolatedPrice;

            // Convert the extrapolated price to a string with 4 decimal places
            let priceString = extrapolatedPrice.toFixed(4);

            // Update the field in the record with the formatted string
            cursor.value[field] = priceString;

            // Write the updated record back into the store
            await cursor.update(cursor.value);

        }
        // Move to the next record in the store
        cursor = await cursor.continue();

    }

    // Close the transaction
    await tx.done;
    db.close();
}
