// SupportLevels.js

import { openDB } from 'idb';
import { getAssets, getCurrentVersion, updateAssetField } from './db';

export async function supportLevels(assetSymbol = "")  {
    console.log("Support Levels");
    const assets = assetSymbol ? (typeof assetSymbol === 'string' ? [{ symbol: assetSymbol }] : assetSymbol) : await getAssets(); 
    const interval = "1day";
    for (const symbol of assets) {
        const tableName = `${symbol.replace('/', '_')}-${interval}`;
        //console.log("Support Levels Table ", tableName);
        try {
            const data = await fetchData(tableName);
            const startIndex = data.length - 1;
            const longTerm = 26;
            const shortTerm = 13;
            const levelPrices = await calculateSupportResistance(data, longTerm, shortTerm, startIndex );
            //console.log(levelPrices);
            const maxValue = Math.max(...levelPrices);
            const minValue = Math.min(...levelPrices);
            //console.log("Max ", maxValue);
            //console.log("Min ", minValue);
            await updateAssetField(symbol, "ath",maxValue);
            await updateAssetField(symbol, "atl",minValue);
            let i = 1;
            const lastdayclose = data[data.length-1].close;
            const sortedPrices = sortByMinimumDistance(levelPrices, lastdayclose);
            //console.log(sortedPrices);
            for (const rsprices of sortedPrices) {
                const priceAsString = rsprices.toFixed(4);
                await updateAssetField(symbol,  `rs${i+10}`, priceAsString);
                //console.log("Updating: ",`rs${i+10}`);
                i++;
                if (i === 10) {
                    break;
                }
            }
        } catch (error) {
            console.error(error);
            return null;
        } 
/*
        try {
            const data = await fetchData(tableName);
            const startIndex = data.length - 250;
            const longTerm = 100;
            const shortTerm = 20;
            const levelPrices = await calculateSupportResistance(data, longTerm, shortTerm, startIndex );
            let i = 1;
            const lastdayclose = data[data.length-1].close;
            const sortedPrices = sortByMinimumDistance(levelPrices, lastdayclose);
            //console.log(sortedPrices);
            for (const rsprices of sortedPrices) {
                updateAssetField(symbol,  `rs${i}`, rsprices);
                //console.log("Updating: ",`rs${i}`);
                i++;
                if (i === 10) {
                    break;
                }
            }
        } catch (error) {
            console.error(error);
            return null;
        }
*/
    }
}

async function calculateSupportResistance(data, longTerm, shortTerm, startIndex) {
    const longTermRange = longTerm;
    const shortTermRange = shortTerm;
    let extremes = [];
    let currentStartIndex = startIndex;
    let previousExtremeType = null;
    while (currentStartIndex > 0) {
        let effectiveRange = currentStartIndex >= longTermRange ? longTermRange : currentStartIndex + 1;
        let { highpoint, lowpoint } = getExtremes(currentStartIndex, effectiveRange, data);
        let mostRecentExtreme = highpoint.index > lowpoint.index ? highpoint : lowpoint;

        if (previousExtremeType === null || previousExtremeType !== (mostRecentExtreme === highpoint ? 'peak' : 'trough')) {
            extremes.push({
                date: data[mostRecentExtreme.index].date,
                ema10: data[mostRecentExtreme.index].ema10,
                type: mostRecentExtreme === highpoint ? 'peak' : 'trough',
                index: mostRecentExtreme.index
            });
        }

        previousExtremeType = mostRecentExtreme === highpoint ? 'peak' : 'trough';
        currentStartIndex = mostRecentExtreme.index - 1;
    }

    let levelPrices = extremes.map(extreme => {
        const startIndex = Math.max(extreme.index - shortTermRange, 0);
        const endIndex = Math.min(extreme.index + shortTermRange, data.length - 1);
        let priceRange = data.slice(startIndex, endIndex + 1);

        if (priceRange.length === 0) {
            //console.error("Empty price range for index", extreme.index);
            return null;
        }

        if (extreme.type === 'peak') {
            return Math.max(...priceRange.map(p => p.high));
        } else {
            return Math.min(...priceRange.map(p => p.low));
        }
    }).filter(price => price !== null); // Filter out null prices

    return levelPrices;
};

function getExtremes(startIndex, range, data) {
    let highpoint = { value: -Infinity, index: -1 };
    let lowpoint = { value: Infinity, index: -1 };

    for (let i = startIndex; i > startIndex - range && i >= 0; i--) {
        
         if (data[i] && data[i].ema10 > highpoint.value) {
            highpoint = { value: data[i].ema10, index: i };
            //console.log("high", data[i].ema10, "date ", data[i].date);
         }
        if (data[i] && data[i].ema10 < lowpoint.value) {
            lowpoint = { value: data[i].ema10, index: i };
            //console.log("low", data[i].ema10, "date ", data[i].date);

        }
    }
    //console.log("low ", lowpoint);
    //console.log("high ", highpoint);
    return { highpoint, lowpoint };
}

async function fetchData(tableName) {
    try {
        const currentVersion = await getCurrentVersion();
        const db = await openDB('AssetsDatabase', currentVersion);
        const transaction = db.transaction(tableName, 'readonly');
        const objectStore = transaction.objectStore(tableName);
        const allRecords = await objectStore.getAll();
        db.close();
        return transformData(allRecords);
    } catch (error) {
        console.error(`Error fetching data: ${error.message}`);
        throw error;
    }
}

function transformData(initialData) {
    return initialData.map(item => ({
        date: item.date,
        open: parseFloat(item.open),
        low: parseFloat(item.low),
        high: parseFloat(item.high),
        close: parseFloat(item.close),
        volume: item.volume ? parseInt(item.volume, 10) : null, // Handle cases where volume might be undefined
        ema10: parseFloat(item.ema10),
        ema20: parseFloat(item.ema20),
        ema50: parseFloat(item.ema50),
        ema100: parseFloat(item.ema100),
        ema200: parseFloat(item.ema200),
        atr: item.atr ? parseFloat(item.atr) : null, // Handle cases where atr might be undefined
    }));
}

function sortByMinimumDistance(arr, specifiedNumber) {
    // A compare function that uses the absolute difference from the specified number
    function compareDistanceFromNumber(a, b) {
      const distanceA = Math.abs(a - specifiedNumber);
      const distanceB = Math.abs(b - specifiedNumber);
  
      // If distances are equal, the order is determined by the actual values to maintain consistency
      if (distanceA === distanceB) {
        return a - b;
      }

      // Otherwise, sort based on which is closer to the specified number
      return distanceA - distanceB;
    }
  
    // Return the sorted array without altering the original one
    return arr.slice().sort(compareDistanceFromNumber);
  }
