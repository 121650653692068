// db.js

import { openDB } from 'idb';

//let db;
let dbPromise;

export async function initializeDB() {
  if (!dbPromise) {
    const currentVersion = await getCurrentVersion();
    dbPromise = await openDB('AssetsDatabase', currentVersion); // Make sure to wait for the promise
  }
  return dbPromise;
}

// Function to get the current version of the database
export async function getCurrentVersion() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('AssetsDatabase');
    request.onsuccess = event => {
      const db = event.target.result;
      resolve(db.version);
      db.close();
    };
    request.onerror = event => reject('Database error: ' + event.target.errorCode);
  });
}

export async function fetchValue(symbol, fieldName) {
  try {
    const currentVersion = await getCurrentVersion();
    const db = await openDB('AssetsDatabase', currentVersion);
    const transaction = db.transaction('assets', 'readonly');
    const objectStore = transaction.objectStore('assets');
    const index = objectStore.index('symbol');
    const record = await index.get(symbol);
    db.close();
    if (record && fieldName in record) {
      return record[fieldName];
    } else {
      console.error(`Record not found for symbol: ${symbol}, or field: ${fieldName} does not exist`);
      return null;
    }
  } catch (error) {
    console.error(`Error fetching value: ${error.message}`);
    throw error;
  }
}

// RESET DATABASE FUNCTION *****************************************************
export async function resetDatabase() {
  // Step 1: Fetch and parse the symbols.csv file
  const response = await fetch('/symbols.csv');
  const text = await response.text();
  let assets = text.split('\n')
    .map(line => line.trim().split(',')[0]) // We only need the first column (symbol)
    .filter(symbol => symbol); // Filter out empty symbols

  // Step 2: Open the database with a new version
  
  const currentVersion = await getCurrentVersion();
  const newVersion = currentVersion + 1;
  const db = await openDB('AssetsDatabase', newVersion, {
    upgrade(db) {
      console.log("Deleting existing object stores");
      Array.from(db.objectStoreNames).forEach(storeName => {
        db.deleteObjectStore(storeName);
        console.log("Deleting: ", storeName);
      });

      // Create new object stores

      console.log("Creating assets");
      db.createObjectStore("assets", { keyPath: "symbol" })
        .createIndex("symbol", "symbol", { unique: false });
      
      console.log("Creating forexdates");
      db.createObjectStore("forexdates", { keyPath: ["date", "impact", "time", "title", "country"] })
        .createIndex("dateAndImpact", ["date", "impact"], { unique: false });
      
      console.log("Creating tradelog");
      db.createObjectStore("tradelog", { keyPath: ["date", "asset", "tradetype", "action", "system", "delay"] })
        .createIndex("date", "date", { unique: false });
      
      console.log("Creating exchangetimes");
      db.createObjectStore("exchangetimes", { keyPath: "code" })
        .createIndex("code", "code", { unique: false });
      
      //console.log("Creating streamdata");
      //db.createObjectStore("streamdata", { keyPath: ["date", "symbol", "price"] })
      //  .createIndex("date", "date", { unique: false });
      
      // Create interval-based tables for each asset
      const intervals = ["1month", "1week", "1day", "4h", "1h", "15min", "1min"];
      assets.forEach(symbol => {
        const symbolFormatted = symbol.replace('/', '_');
        intervals.forEach(interval => {
          const tableName = `${symbolFormatted}-${interval}`;
          if (!db.objectStoreNames.contains(tableName)) {
            console.log("Creating ", tableName);
            db.createObjectStore(tableName, { keyPath: "date" })
              .createIndex("date", "date", { unique: false });
          }
        });
      });
    }
  });

  console.log('Database reset and reinitialized successfully.');
  db.close();
}


// REOPEN DATABASE WITH UPGRADE FUNCTION *****************************************************
export async function reopenDatabaseWithUpgrade(callback) {
  const currentVersion = await getCurrentVersion();
  const db = await openDB('AssetsDatabase', currentVersion);
  db.close();
  console.log("Setting up new reopenDatabaseWithUpgrade promise");
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('AssetsDatabase', currentVersion + 1);
    request.onupgradeneeded = event => {
      const db = event.target.result;
      callback(db);
    };
    request.onsuccess = event => {
      const db = event.target.result;
      console.log("reopenDatabaseWithUpgrade successful");
      resolve(db);
    };
    request.onerror = event => {
      reject('Database error: ' + event.target.errorCode);
      console.log("reopenDatabaseWithUpgrade failed");
    };
  });
}


// GET ASSETS *****************************************************

export const getAssets = async () => {
  let db;
  try {
    db = await openDB('AssetsDatabase');
    const transaction = db.transaction('assets', 'readonly');
    const objectStore = transaction.objectStore('assets');
    const assets = await objectStore.getAll();
    db.close();
    return assets.map(asset => asset.symbol);  // Assuming each asset has a 'symbol' property
  } catch (error) {
    console.error('Error getting assets:', error);
    throw error;
  }
};

export const saveAssets = async (assets) => {

  try {
    const db = initializeDB();
    const tx = db.transaction('assets', 'readwrite');
    const store = tx.objectStore('assets');
    for (const asset of assets) {
      store.put(asset);  // no need to await inside a loop, it slows down the process
    }
    await tx.done;  // this will wait for all puts to be completed
    db.close();
  } catch (error) {
    console.error('Error saving assets:', error);
    throw error;  // re-throw the error after logging it
  }
};

// UPDATE ASSET FIELD ***********************************************************

export async function updateAssetField(symbol, fieldName, value) {

  // Open the existing database
  const currentVersion = await getCurrentVersion();
  const db = await openDB('AssetsDatabase', currentVersion);

  // Create a transaction, access the 'assets' object store, and get the asset record
  const tx = db.transaction('assets', 'readwrite');
  const store = tx.objectStore('assets');
  const asset = await store.get(symbol);

  if (!asset) {
    console.error(`Asset with symbol ${symbol} not found.`);
    return;
  } else {
    //console.log("Updating Asset", symbol)
  }

  // Update the field and put the updated asset record back into the object store
  asset[fieldName] = value;
  await store.put(asset);

  // Wait for the transaction to complete
  await tx.done;

  db.close();

  //console.log(`Updated asset ${symbol}: set ${fieldName} to ${value}`);

}
// GET PRICE DATA FROM PRICE TABLES ***********************************************************

export async function fetchPrices(symbol, interval, reverse) {
  try {
    const tableName = `${symbol.replace('/', '_')}-${interval}`;
    const currentVersion = await getCurrentVersion();
    const db = await openDB('AssetsDatabase', currentVersion);
    const transaction = db.transaction(tableName, 'readonly');
    const objectStore = transaction.objectStore(tableName);
    const allRecords = await objectStore.getAll();
    db.close();
    if (!reverse) {
      return allRecords;
    } else {
      return allRecords.reverse();
    }
  } catch (error) {
    console.error(`Error fetching data: ${error.message}`);
    throw error;
  }
}
// GET AN ASSET RECORD FROM assets ***********************************************************

export async function fetchAsset(symbol) {
  try {
    const currentVersion = await getCurrentVersion();
    const db = await openDB('AssetsDatabase', currentVersion);
    const transaction = db.transaction('assets', 'readonly');
    const objectStore = transaction.objectStore('assets');
    const index = objectStore.index('symbol');
    const record = await index.get(symbol);
    db.close();
    return record;

  } catch (error) {
    console.error(`Error fetching value: ${error.message}`);
    throw error;
  }
}

//********************************************************************************************************** */
// WRITE TRADE LOG

export const writeTradeLog = async (datetime, asset, tradetype, action, system, price, stopprice, tradeconditions, riskratio, maxsize) => {
  const now = new Date();
  const datetimeObj = new Date(datetime);
  if (isNaN(datetimeObj.getTime())) {
    throw new Error("Invalid datetime format");
  }
  const delay = Math.round((now - datetimeObj) / 1000);

  // Save to database
  const db = await openDB('AssetsDatabase');
  const tx = db.transaction('tradelog', 'readwrite');
  const store = tx.objectStore('tradelog');
  const tradeLogEntry = { date: datetime, asset, tradetype, action, system, delay, price, stopprice, tradeconditions, riskratio, maxsize };
  await store.put(tradeLogEntry);
  db.close();

// Prepare email content
const emailContent = `
Date: ${tradeLogEntry.date}
Asset: ${tradeLogEntry.asset}
Trade Type: ${tradeLogEntry.tradetype}
Action: ${tradeLogEntry.action}
System: ${tradeLogEntry.system}
Delay: ${tradeLogEntry.delay}
Price: ${tradeLogEntry.price}
Stop Price: ${tradeLogEntry.stopprice}
Trade Conditions: ${tradeLogEntry.tradeconditions}
Risk Ratio: ${tradeLogEntry.riskratio}
Max Size: ${tradeLogEntry.maxsize}
`;

// Send email content to your server
fetch(`${process.env.REACT_APP_BACKEND_URL}/send-email`, {
  method: 'POST',
  headers: {
      'Content-Type': 'application/json',
  },
body: JSON.stringify({ emailContent })
})
.then(response => response.text())
.then(data => console.log(data))
.catch((error) => console.error('Error:', error));
};


//*************************************************************************************************
export function sixSigFig(num, n) {
  if (num === 0) {
    return 0;
  }

  const d = Math.ceil(Math.log10(num < 0 ? -num : num));
  const power = n - d;

  const magnitude = Math.pow(10, power);
  const shifted = Math.round(num * magnitude);
  return shifted / magnitude;
}
