// PriceBounce.js

import { fetchAsset } from './db';
export const priceBounce = async (data, suppField, mPeriod=20) => {
    let bounce = "";
    for (let icount = 0; icount < 2 && bounce === ""; icount++) {
        const currentData = data[icount];
        const { close, low, open, high } = currentData;
        const previousData = data[icount+1];
        const { prevLow, prevOpen, prevHigh } = previousData;
        let suppLevel = currentData[suppField];
        //console.log("suppLevel ",suppLevel,"close ",close,"open ",open,"low ",low, "high ",high);
        // BULL BOUNCE
        if ((close > suppLevel && close > open && low < suppLevel) || 
        (close > suppLevel && close > open && prevLow < suppLevel && prevOpen > open)) {
            //console.log("Check for possible BULL BOUNCE");
            for (let counter = 1; counter < mPeriod && bounce === ""; counter++) {
                const nextData = data[counter];
                const { high, low } = nextData;
                let nextSuppLevel = nextData[suppField];

                if (high < nextSuppLevel) {
                    bounce = "";
                    break; 
                } else if (low > nextSuppLevel) {
                    bounce = "bull";
                    break; 
                }
            }
        // BEAR BOUNCE    
        } else if ((close < suppLevel && close < open && high > suppLevel) ||
        (close < suppLevel && close < open && prevHigh > suppLevel && prevOpen < open )) {
            //console.log("Check for possible BEAR BOUNCE");
            for (let counter = 1; counter < mPeriod && bounce === ""; counter++) {
                const nextData = data[counter];
                const { high, low } = nextData;
                let nextSuppLevel = nextData[suppField];

                if (low > nextSuppLevel) {
                    bounce = "";
                    break; 
                } else if (high < nextSuppLevel) {
                    bounce = "bear";
                    break; 
                }
            }
        }
    }
    return bounce;
}


export const pivotBounce = async (data, suppField, symbol, mPeriod=20) => {
    let bounce = "", suppLevel=0;
    for (let icount = 0; icount < 2 && bounce === ""; icount++) {
        const currentData = data[icount];
        //console.log(currentData);
        const { close, low, open, high } = currentData;
        const asset = await fetchAsset(symbol);
        if (suppField==="dailypivot")  {
            suppLevel = asset.dailypivot;
        }
        if (suppField==="dailypivots1")  {
            suppLevel = asset.dailypivots1;
        }
        if (suppField==="dailypivots2")  {
            suppLevel = asset.dailypivots2;
        }
        if (suppField==="dailypivotr1")  {
            suppLevel = asset.dailypivotr1;
        }
        if (suppField==="dailypivotr2")  {
            suppLevel = asset.dailypivotr2;
        }
        if (close > suppLevel && close > open && low < suppLevel) {
            // Check for possible BULL BOUNCE
            for (let counter = 1; counter < mPeriod && bounce === ""; counter++) {
                const nextData = data[counter];
                const { high, low } = nextData;

                if (high < suppLevel) {
                    bounce = "";
                    break; 
                } else if (low > suppLevel) {
                    bounce = "bull";
                    break; 
                }
            }
        } else if (close < suppLevel && close < open && high > suppLevel) {
            // Check for possible BEAR BOUNCE
            for (let counter = 1; counter < mPeriod && bounce === ""; counter++) {
                const nextData = data[counter];
                const { high, low } = nextData;
                

                if (low > suppLevel) {
                    bounce = "";
                    break; 
                } else if (high < suppLevel) {
                    bounce = "bear";
                    break; 
                }
            }
        }
    }
    return { bounce, suppLevel };
}