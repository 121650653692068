// TradeLog.js

import React, { useState, useEffect } from 'react';
import { openDB } from 'idb';

const TradeLog = () => {
    const [tradeLogEntries, setTradeLogEntries] = useState([]);

    const fetchTradeLogEntries = async () => {
        const db = await openDB('AssetsDatabase');
        const tx = db.transaction('tradelog', 'readonly');
        const store = tx.objectStore('tradelog');
        const allEntries = await store.getAll();
        const lastFiftyEntries = allEntries.slice(-200); // Get the last 50 entries
        const sortedEntries = lastFiftyEntries.reverse(); // Reverse the order
        setTradeLogEntries(sortedEntries); // Set the state with reversed entries
        db.close();
    };
    

    useEffect(() => {
        fetchTradeLogEntries();
        const interval = setInterval(fetchTradeLogEntries, 30000); // Refresh every 30 seconds
        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    return (
        <div>
            <h4>Trade Log</h4>
            <table>
                <thead>
                    <tr>
                        <th>Datetime</th>
                        <th>Asset</th>
                        <th>Trade Type</th>
                        <th>Action</th>
                        <th>System</th>
                        <th>Price</th>
                        <th>Delay (s)</th>
                        <th>Stop</th>
                        <th>Type</th>
                        <th>Risk Ratio</th>
                    </tr>
                </thead>
                <tbody>
                    {tradeLogEntries.map((entry, index) => (
                        <tr key={index}>
                            <td>{entry.date}</td>
                            <td>{entry.asset}</td>
                            <td>{entry.tradetype}</td>
                            <td>{entry.action}</td>
                            <td>{entry.system}</td>
                            <td>{entry.price}</td>
                            <td>{entry.delay}</td>
                            <td>{entry.stopprice}</td>
                            <td>{entry.tradeconditions}</td>
                            <td>{entry.riskratio}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TradeLog;
