// App.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { openDB } from 'idb';
import Modal from 'react-modal';
import './App.css';
import { getData } from './GetData';
import { analyseMarket } from './AnalyseMarket';
import { updateIndicators } from './UpdateIndicators';
import { getCurrentVersion, updateAssetField, resetDatabase } from './db.js';
import { calculateTrendLines } from './GetTrends';
import { saveAs } from 'file-saver';
import { supportLevels } from './SupportLevels';
import { processCsvData } from './WeeklyForex';
import TodaysForex from './ForexData'
import { trendLines } from './TrendLines';
import { addAssets } from './AssetFunctions';
import { tradeHunter } from './TradeHunter';
import { tradeTrigger } from './TradeTrigger';
import TradeLog from './TradeLog'; 
import ChartModal from './ChartModal';
import { getFibonacci } from './GetFibonacci';
import { getExchangeTimes } from './GetExchangeTimes';
import { streamData, stopStreamData } from './streamMultiAssetData';
import { setupTrade } from './PlaceTrade';

Modal.setAppElement('#root');

// ADD ASSET POPUP ***********************************************************************

const fields = [
  'symbol', 'majordirection', 'majordate1', 'majorprice1', 'majordate2', 'majorprice2',
  'minordirection', 'minordate1', 'minorprice1', 'minordate2', 'minorprice2', 'rs1', 'rs2', 'rs3', 'rs4', 'rs5', 'rs6', 'rs7', 'rs8', 'rs9', 'rs10'
];
// ADD ASSET MODAL SCREEN POPUP **************************************************************
const AddAssetModal = ({ isOpen, onRequestClose }) => {
  const [formData, setFormData] = useState({
    symbol: '', majordirection: '', majordate1: '', majorprice1: '',
    majordate2: '', majorprice2: '', minordirection: '', minordate1: '',
    minorprice1: '', minordate2: '', minorprice2: '', rs1: '', rs2: '', rs3: '', rs4: '', rs5: '', rs6: '', rs7: '', rs8: '', rs9: '', rs10: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Inside AddAssetModal component
  const handleSubmit = async (e) => {
    e.preventDefault();
    const assetDataArray = fields.map(field => formData[field] || '');
    await addAssets([assetDataArray]); // Call addAssets directly here
    onRequestClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <form onSubmit={handleSubmit}>
        {fields.map((field) => (
          <div key={field}>
            <label htmlFor={field}>{field.charAt(0).toUpperCase() + field.slice(1)}</label>
            <input
              id={field}
              name={field}
              value={formData[field]}
              onChange={handleChange}
              required={field === 'symbol'} // Only symbol is required
            />
          </div>
        ))}
        <button type="submit">Submit</button>
      </form>
    </Modal>
  );
};

//**** VIEW & EDIT ASSET MODAL POPUP *************************************************

const AssetDetailsModal = ({ asset, isOpen, onRequestClose, onUpdateAsset }) => {
  const [formData, setFormData] = useState({ ...asset });

  useEffect(() => {
    setFormData({ ...asset }); // Update form data when asset changes
  }, [asset]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await onUpdateAsset(formData);
    onRequestClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="asset-modal">
      <form onSubmit={handleSubmit} className="asset-form">
        {Object.entries(formData).map(([key, value]) => (
          key !== 'symbol' && (
            <div className="form-group" key={key}>
              <label htmlFor={key} className="form-label">{key}</label>
              <input
                id={key}
                name={key}
                value={value}
                onChange={handleChange}
                className="form-input"
              />
            </div>
          )
        ))}
        <div className="form-buttons">
          <button type="submit" className="submit-button">Save Changes</button>
          <button type="button" className="cancel-button" onClick={onRequestClose}>Cancel</button>
        </div>
      </form>
    </Modal>
  );
};


// TABLE LIST POPUP ***********************************************************

const TableListPopup = ({ isOpen, onRequestClose }) => {
  const [tableNames, setTableNames] = useState([]);

  useEffect(() => {
    const fetchTableNames = async () => {
      const names = await getTableNames();
      setTableNames(names);
    };

    fetchTableNames();
  }, []);

  const downloadTableData = async (tableName) => {
    const db = await openDB('AssetsDatabase');
    const tx = db.transaction(tableName, 'readonly');
    const store = tx.objectStore(tableName);
    const allData = await store.getAll();
    db.close();

    // Convert data to CSV format
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += Object.keys(allData[0]).join(",") + "\r\n";  // header
    allData.forEach(item => {
      csvContent += Object.values(item).join(",") + "\r\n";
    });

    // Save CSV file
    const blob = new Blob([csvContent], { type: 'text/csv' });
    saveAs(blob, `${tableName}.csv`);
    onRequestClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <ul>
        {tableNames.map((name, index) => (
          <li key={index}>
            <button onClick={() => downloadTableData(name)}>Select</button>
            {name}
          </li>
        ))}
      </ul>
    </Modal>
  );
};

// GET TABLE NAMES ***********************************************************

async function getTableNames() {
  const db = await openDB('AssetsDatabase');
  const tableNames = Array.from(db.objectStoreNames);
  db.close();
  return tableNames;
}

// CALCMAJOR ***********************************************************

export const calcMajor = async (symbol) => {
  try {
    const result = await calculateTrendLines(symbol, "1day");
    const trendSupportResistance = result.trendSupportResistance;

    if (!trendSupportResistance || trendSupportResistance.length === 0) {
      console.error(`No trend support/resistance data returned for symbol: ${symbol}`);
      return;
    }

    // Sort the array from highest to lowest
    const sortedArray = trendSupportResistance.slice().sort((a, b) => b - a);

    // Limit to a maximum of 10 items
    const limitedArray = sortedArray.slice(0, 10);

    if (sortedArray.length > 10) {
      console.error('More than 10 trend support/resistance values returned. Ignoring extra values.');
    }

    // Loop through each item and store in the asset
    for (let i = 0; i < limitedArray.length; i++) {
      const fieldName = `rs${i + 1}`;
      await updateAssetField(symbol, fieldName, limitedArray[i]);
    }

  } catch (error) {
    console.error(`Error during Major Trend Calc.: ${error.message}`);
  }
}



// MAIN APP ***********************************************************

const App = () => {
  console.log("Opening App");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [isTableListPopupOpen, setIsTableListPopupOpen] = useState(false);

  const [assets, setAssets] = useState([]);

  const [focusedRow, setFocusedRow] = useState(null);  // New state variable for toggle functionality

  const [newAsset, setNewAsset] = useState({
    symbol: '',
    default_interval: '1day',
    monthlytrend: '',
    weeklytrend: '',
    dailytrend: '',
    updated: new Date().toISOString(),
  });

  // View/Update Asset Modal popup states
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [isAssetModalOpen, setIsAssetModalOpen] = useState(false);
  const openAssetModal = (asset) => {
    setSelectedAsset(asset);
    setIsAssetModalOpen(true);
  };

  const updateAsset = async (updatedAsset) => {
    // Implement the logic to update the asset in IndexedDB
    // After updating, fetch the updated asset list
    await fetchAssets();
  };

  //***  CHART & FOREX
  //const chartRoot = useRef(null);
  const forexRoot = useRef(null);

  //*** CHART
  
  // State for ChartModal
  const [chartModalOpen, setChartModalOpen] = useState(false);
  const [selectedSymbol, setSelectedSymbol] = useState(null);

  const openChartModal = (symbol) => {
    setSelectedSymbol(symbol);
    setChartModalOpen(true);
  };

  
 
  // Set status message on screen during background task processing
  const [statusMessage, setStatusMessage] = useState('');

  // BACKGROUND UPDATE ********************************************************************************

  const [intervalId, setIntervalId] = useState(null);
  const [isRunning, setIsRunning] = useState(false);

  const runInBackground = useCallback(async () => {
    const dateInitial = new Date();
    setStatusMessage(" Running: " + dateInitial.toISOString().slice(0, 19).replace('T', ' '));
    const interval = ["1min", "15min", "1h", "4h"];
    const intervalUpdates = await getData("", interval); 
    console.log(intervalUpdates);
    await updateIndicators("", interval); 
    await analyseMarket("", intervalUpdates); // process Updates Analyse
    // Check for 1 hour update 
    if (intervalUpdates.includes("1h")) {
      console.log("Run TradeHunter");
      await tradeHunter();
    }
    await tradeTrigger("");
    const dateFinal = new Date();
    const runTime = (dateFinal - dateInitial) / 1000;
    setStatusMessage(" Completed: " + dateFinal.toISOString().slice(0, 19).replace('T', ' ') + " runtime: " + runTime.toFixed(1) + " seconds");
    await fetchAssets();
  }, []);


  const scheduleNextRun = (initialDate) => {
    const delayUntilNextMinute = (60 - initialDate.getSeconds()) * 1000 - initialDate.getMilliseconds();
  
    setTimeout(() => {
      runInBackground();
      setIntervalId(setInterval(runInBackground, 60000)); // Schedule the interval to repeat every minute
    }, delayUntilNextMinute);
  };
  
  // Toggle background process on and off
  const toggleInterval = () => {
    setIsAudioAllowed(true);
    if (!isRunning) {
      const initialDate = new Date(); // Get the current time
      scheduleNextRun(initialDate); // Schedule the first run at the next full minute based on the current time
      setIsRunning(true); // Update the running state
    } else {
      clearInterval(intervalId); // Clear the existing interval
      setIntervalId(null); // Reset the interval ID
      setIsRunning(false); // Update the running state
    }
  };

  // ADD ASSET HANDLERS ********************************************
  const handleAddClick = () => {
    setIsPopupOpen(true);
  };

  //*******FETCH ASSETS *************************************************************************

  const fetchAssets = async () => {
    try {
      const currentVersion = await getCurrentVersion();
      const db = await openDB('AssetsDatabase', currentVersion);
      const tx = db.transaction('assets', 'readonly');
      const store = tx.objectStore('assets');
      const allAssets = await store.getAll();
      setAssets(allAssets);
      db.close();
    } catch (error) {
      console.error('Error fetching assets or forex data:', error);
    }
  };

  //***** APP REACT ***************************************************************************
  useEffect(() => {
    const initDB = async () => {
      //await resetDatabase();
      //await addAssets();
      await fetchAssets();
    };
    initDB();
  }, []);


  // FOREX AND CHARTS ****************************************************************************

  useEffect(() => {
  //  // Initialize chart root once the component mounts
  //  const chartContainer = document.getElementById('chartContainer');
  //  if (chartContainer && !chartRoot.current) { // Make sure the container exists and root is not already created
  //    chartRoot.current = ReactDOMClient.createRoot(chartContainer);
  //  }

    // Initialize forex root once the component mounts
    const forexContainer = document.getElementById('forexlist');
    if (forexContainer && !forexRoot.current) { // Make sure the container exists and root is not already created
      forexRoot.current = ReactDOMClient.createRoot(forexContainer);
    }
  }, []); // The empty dependency array ensures this effect runs only once

    // *** FOREX FACTORY DOWNLOAD *********************************************************************

  const weeklyForex = async () => {
    fetch('/download-csv')
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          processCsvData(data.csv); // assuming the CSV comes in a property named csv
        } else {
          console.error('Failed to download CSV');
        }
      })
      .catch(error => console.error('Error fetching CSV:', error));
  };

  // SYSTEM SETUP ***********************************************************
  const systemSetup = async () => {
       // Call the addAssets function with the entire array of assets
      await addAssets();  
      await fetchAssets();

  };

  // DELETE ASSETS ***********************************************************

  const deleteAsset = async (symbol) => {
    try {
      console.log("delete called");
      const currentVersion = await getCurrentVersion();
      const db = await openDB('AssetsDatabase', currentVersion);
      const tx = db.transaction('assets', 'readwrite');  // Create a read-write transaction
      const store = tx.objectStore('assets');  // Access the 'assets' object store
      await store.delete(symbol);  // Request to delete the specified asset record
      await tx.done;  // Ensure the transaction is complete

      console.log(`Asset ${symbol} deleted successfully.`);
      await fetchAssets();
      db.close();
    } catch (error) {
      console.error('Error deleting asset:', error);
    }


    try {
      const currentVersion = await getCurrentVersion();
      const newVersion = currentVersion + 1;
      const db = await openDB('AssetsDatabase', newVersion, {
        upgrade(db) {
          const intervals = ["1month", "1week", "1day", "4h", "1h", "15min", "1min"];
          const symbolFormatted = symbol.replace('/', '_');
          intervals.forEach(interval => {
            const tableName = `${symbolFormatted}-${interval}`;
            if (db.objectStoreNames.contains(tableName)) {
              db.deleteObjectStore(tableName);
              console.log("Table deleted ", tableName);
            }
          });
        }
      });
      db.close();
    } catch (error) {
      throw new Error('Error reopening the database: ' + error.message);
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAsset({ ...newAsset, [name]: value });
  };

  const toggleFocus = (index) => {
    setFocusedRow(focusedRow === index ? null : index);
  };

  // RESISTANCE SUPPORT **************************************************************************************
  /*
  const getSupportLevels = async (symbol) => {
    await supportLevels(symbol);  // Get long term support/resistance 
    console.log(`Support levels for ${symbol}`);

    await fetchAssets();

  };
  */
  // IMPORT PROCESS ************************************************************
  const importProcess = async (symbol, interval) => {

    console.log(`Starting import process for ${symbol} and ${interval}`);

    //await streamData();
    //console.log("Data stream initiated");

    await getData(symbol, interval);  // Step 1: Get data
    console.log(`Data fetched for ${symbol}`);

    await trendLines("", "");  // Step 2: Trend Lines
    console.log(`Trend lines completed for ${symbol}`);

    await getExchangeTimes();  // Step 3: Get Exchange Times data
    console.log(`Exchange Times Imported`);

    await updateIndicators(symbol, interval);  // Step 4: Update indicators
    console.log(`Indicators updated for ${symbol}`);

    await supportLevels(symbol);  // Step 5. Get long term support/resistance 
    console.log(`Support levels completed for ${symbol}`);

      await getFibonacci(symbol); // Step 6: Create Fibonacci Levels
    console.log(`Fibonacci levels created for ${symbol}`);

    await analyseMarket(symbol, interval);  // Step 7: Analyse market
    console.log(`Market analysed for ${symbol}`);

    await tradeHunter(); // Step 8 Run Trade Hunter
    console.log(`Trade Hunter completed`);

    await weeklyForex();

    await fetchAssets();

  };

  // *** START THE DAY

  const startTheDay = async (symbol = "") => {

    const interval = ["1month", "1week", "1day"];

    //stopStreamData();

    //await streamData();
    //console.log("Data stream initiated");

    await getData(symbol, interval);  // Step 1: Get data
    console.log(`Data fetched for ${symbol}`);

    await trendLines("", "");  // Step 4: Trend Lines
    console.log(`Trend lines completed for ${symbol}`);

    await updateIndicators(symbol, interval);  // Step 2: Update indicators
    console.log(`Indicators updated for ${symbol}`);

    await supportLevels(symbol);  // Step 3. Get long term support/resistance 
    console.log(`Support levels completed for ${symbol}`);

    await analyseMarket(symbol, interval);  // Step 5: Analyse market
    console.log(`Market analysed for ${symbol}`);

    await tradeHunter(); // Step 6 Run Trade Hunter
    console.log(`Trade Hunter completed`);

    await weeklyForex();

    await fetchAssets();

  }

  // ************* Analyse Market 
  const analyseSolo = async (symbol, interval) => {
    await analyseMarket(symbol, interval);  // Step 5: Analyse market
    console.log(`Market analysed for ${symbol}`);
  }

  // ****COMBINED FUNCTIONS ************************************************************************************
  //const combinedFunction = (symbol, interval, index) => {
  //  chartData(symbol, interval,);
  //  if (focusedRow !== index) {
  //    setFocusedRow(index);
  //  }
  //};

  // *** RESET ALL ***********************************************************************
  const resetAll = async () => {

    console.log("Stopping Stream if running");
    stopStreamData();
    console.log("Stopped Stream");
    
    console.log("Resetting Database");
    await resetDatabase();

    console.log("refresh assets onscreen");
    await fetchAssets();
  };

 // *** AUDIO ALERT ***********************************************************************

// State to track if audio playback is allowed
const [isAudioAllowed, setIsAudioAllowed] = useState(false);

// Add a new state to track changes in the 'trade' cells for audio alert triggering 
const [tradeCellContent, setTradeCellContent] = useState({});

// Function to update the tradeCellContent state whenever relevant data changes
const updateTradeCellContent = (assets) => {
  const newContent = {};
  assets.forEach((asset, index) => {
    newContent[index] = {
      superearlyadopter: asset.superearlyadopter,
      earlyadopter: asset.earlyadopter,
      earlyadopterpp: asset.earlyadopterpp,
      lazytraderema: asset.lazytraderema
    };
  });
  setTradeCellContent(newContent);
};

// useEffect to detect changes in 'trade' cells
useEffect(() => {
  const hasChanged = assets.some((asset, index) => {
    const currentContent = tradeCellContent[index];
    return !currentContent || 
           asset.superearlyadopter !== currentContent.superearlyadopter ||
           asset.earlyadopter !== currentContent.earlyadopter ||
           asset.earlyadopterpp !== currentContent.earlyadopterpp ||
           asset.lazytraderema !== currentContent.lazytraderema;
  });

  if (hasChanged) {
    updateTradeCellContent(assets);
  }
}, [assets, tradeCellContent]);

// useEffect to check for trade cell content changes and play sound
useEffect(() => {
  const playSound = () => {
    if (isAudioAllowed) {
      const audio = document.getElementById('mySound');
      if (audio) {
        audio.play().catch(e => {
            console.error("Error playing sound:", e);
        });
      } else {
        console.log("Audio element not found");
      }
    } else {
      console.log("Audio not allowed");
    }
  };
  
  const hasTradeAlert = Object.values(tradeCellContent).some(content =>
    content && (content.superearlyadopter || content.earlyadopter || content.earlyadopterpp || content.lazytraderema)
  );

  if (hasTradeAlert) {
    playSound();
  }
}, [tradeCellContent, isAudioAllowed]);

const stopSound = () => {
  const audio = document.getElementById('mySound');
  if (audio) {
    audio.pause();
    audio.currentTime = 0; // Reset the sound to the start
  }
};

  // *** JSX ***************************************************************************
  return (
    <div>
      <table>
        <thead>
          <tr><th>Asset</th>
            <th colSpan="5">Trends</th>
            <th colSpan="2">EMA Stack</th>
            <th>Updated</th>
            <th colSpan="1">Early Adopter</th>
            <th colSpan="3">1m Entry</th>
            <th colSpan="1">Lazy Trader</th>
            <th colSpan="1">10/20 Entry</th>
            <th colSpan="5">Ema 10/20 Bounce</th>
            <th colSpan="3">Ema Bounces 1Day</th>
            <th colSpan="3">Space</th>
            <th colSpan="2">Close</th>
            <th>Solo</th>
            <th colSpan="4">Functions
              <input
                type="text"
                name="symbol"
                value={newAsset.symbol}
                onChange={handleInputChange}
                onFocus={() => setNewAsset({ ...newAsset, symbol: '' })}
                maxLength={10}
                placeholder="Symbol"
              /><button onClick={() => addAssets([newAsset.symbol])}> Add</button>
            </th>
          </tr>
        </thead>
        <thead>
          <tr><th></th>
            <th>M</th>
            <th>W</th>
            <th>D</th>
            <th>4h</th>
            <th>1h</th>
            <th>1D</th>
            <th>4h</th>
            <th></th>
            <th>Trade</th>
            <th>Super</th>
            <th>Early</th>
            <th>PP</th>
            <th>Trade</th>
            <th>1D</th>
            <th>1D</th>
            <th>4h</th>
            <th>1h</th>
            <th>15m</th>
            <th>1m</th>
            <th>200</th>
            <th>100</th>
            <th>50</th>
            <th>head</th>
            <th>floor</th>
            <th>atr</th>
            <th>1D</th>
            <th>1m</th>
            <th></th>
            <th>Trade</th>
            <th>Chart</th>
            <th>View</th>
            <th>Del</th></tr>
        </thead>
        <tbody>
          {assets.map((asset, index) => (
            focusedRow === null || focusedRow === index ? (
              <tr key={index}><td>{asset.symbol}</td>
                <td style={{ backgroundColor: asset.monthlytrend === 'bull' ? 'lightgreen' : asset.monthlytrend === 'bear' ? 'lightpink' : '' }}>
                  {typeof asset.monthlytrend === 'object' ? JSON.stringify(asset.monthlytrend) : asset.monthlytrend}
                </td>
                <td style={{ backgroundColor: asset.weeklytrend === 'bull' ? 'lightgreen' : asset.weeklytrend === 'bear' ? 'lightpink' : '' }}>
                  {typeof asset.weeklytrend === 'object' ? JSON.stringify(asset.weeklytrend) : asset.weeklytrend}
                </td>
                <td style={{ backgroundColor: asset.dailytrend === 'bull' ? 'lightgreen' : asset.dailytrend === 'bear' ? 'lightpink' : '' }}>
                  {typeof asset.dailytrend === 'object' ? JSON.stringify(asset.dailytrend) : asset.dailytrend}
                </td>
                <td style={{ backgroundColor: asset.fourhourlytrend === 'bull' ? 'lightgreen' : asset.fourhourlytrend === 'bear' ? 'lightpink' : '' }}>
                  {typeof asset.fourhourlytrend === 'object' ? JSON.stringify(asset.fourhourlytrend) : asset.fourhourlytrend}
                </td>
                <td style={{ backgroundColor: asset.hourlytrend === 'bull' ? 'lightgreen' : asset.hourlytrend === 'bear' ? 'lightpink' : '' }}>
                  {typeof asset.hourlytrend === 'object' ? JSON.stringify(asset.hourlytrend) : asset.hourlytrend}
                </td>
                <td style={{ backgroundColor: asset.dailyemastack === 'Y' ? 'lightgreen' : asset.dailyemastack === 'N' ? 'lightpink' : asset.dailyemastack === 'Y-' ? '#DDFFD5' : asset.dailyemastack === 'N-' ? '#FFD5DC' : '' }}>{asset.dailyemastack}</td>
                <td style={{ backgroundColor: asset.fourhourlyemastack === 'Y' ? 'lightgreen' : asset.fourhourlyemastack === 'N' ? 'lightpink' : asset.fourhourlyemastack === 'Y-' ? '#DDFFD5' : asset.fourhourlyemastack === 'N-' ? '#FFD5DC' : '' }}>{asset.fourhourlyemastack}</td>
                <td>{asset.updated.split(" ")[1]}</td>
                <td class="trade">{asset.readytradeet}</td>
                <td class="tradealert">{asset.superearlyadopter}</td>
                <td class="tradealert">{asset.earlyadopter}</td>
                <td class="tradealert">{asset.earlyadopterpp}</td>
                <td class="trade">{asset.readytradelt}</td>
                <td class="tradealert">{asset.lazytraderema}</td>
                <td style={{ backgroundColor: asset.dailyemabounce === 'Y' ? 'lightgreen' : asset.dailyemabounce === 'N' ? 'lightpink' : '' }}>{asset.dailyemabounce}</td>
                <td style={{ backgroundColor: asset.fourhourlyemabounce === 'Y' ? 'lightgreen' : asset.fourhourlyemabounce === 'N' ? 'lightpink' : '' }}>{asset.fourhourlyemabounce}</td>
                <td style={{ backgroundColor: asset.hourlyemabounce === 'Y' ? 'lightgreen' : asset.hourlyemabounce === 'N' ? 'lightpink' : '' }}>{asset.hourlyemabounce}</td>
                <td style={{ backgroundColor: asset.fifteenminutelyemabounce === 'Y' ? 'lightgreen' : asset.fifteenminutelyemabounce === 'N' ? 'lightpink' : '' }}>{asset.fifteenminutelyemabounce}</td>
                <td style={{ backgroundColor: asset.minutelyemabounce === 'Y' ? 'lightgreen' : asset.minutelyemabounce === 'N' ? 'lightpink' : '' }}>{asset.minutelyemabounce}</td>
                <td style={{ backgroundColor: asset.daily200bounce === 'bull' ? 'lightgreen' : asset.daily200bounce === 'bear' ? 'lightpink' : '' }}>{asset.daily200bounce}</td>
                <td style={{ backgroundColor: asset.daily100bounce === 'bull' ? 'lightgreen' : asset.daily100bounce === 'bear' ? 'lightpink' : '' }}>{asset.daily100bounce}</td>
                <td style={{ backgroundColor: asset.daily50bounce === 'bull' ? 'lightgreen' : asset.daily50bounce === 'bear' ? 'lightpink' : '' }}>{asset.daily50bounce}</td>
                 <td>{asset.headroomatrratio}</td>
                 <td>{asset.floorroomatrratio}</td>
                <td>{asset.dailyatr}</td>
                <td>{asset.lastdayclose}</td>
                <td>{asset.lastclose}</td>
                <td><button onClick={() => toggleFocus(index)} style={{ background: 'none', border: 'none', padding: 0 }}><img src="eye-small.jpg" alt="Toggle" style={{ width: '25px', height: '15px' }} /></button></td>
                <td><button onClick={() => setupTrade(asset.symbol)}>Trade</button></td>
                <td><button onClick={() => openChartModal(asset.symbol)}>Chart</button></td>
                <button onClick={() => openAssetModal(asset)}>View</button>
                <td><button onClick={async () => { await deleteAsset(asset.symbol); }}>Del</button></td>
              </tr>
            ) : null
          ))}
        </tbody>
      </table>
      <div>
      </div>
     <button onClick={() => setIsTableListPopupOpen(true)}>Show Tables</button>
     <TableListPopup isOpen={isTableListPopupOpen} onRequestClose={() => setIsTableListPopupOpen(false)} />
      <button onClick={() => resetAll()}>Reset</button>
      <button onClick={() => systemSetup()}>System Setup</button>
      <button onClick={() => importProcess("", "")}>Import</button>
      <button onClick={() => startTheDay("", "")}>Start The Day</button>
      <button onClick={() => weeklyForex()}>Get Forex Calendar</button>
      <button onClick={() => getFibonacci("")}>Get Fibonacci</button>
      <button onClick={() => getExchangeTimes("")}>Exchange Times</button>
      <button onClick={() => analyseSolo("", "")}>Analyse</button>
      <button onClick={() => streamData()}>Start Stream</button>
      <button onClick={() => stopStreamData()}>Stop Stream</button>
      <button onClick={() => tradeHunter()}>Trade Hunter</button>
      <button onClick={() => tradeTrigger()}>Trade Trigger</button>
      
      <button onClick={toggleInterval}>{isRunning ? 'Stop Monitor' : 'Start Monitor'}</button>
      <audio id="mySound" src="/tradealert.mp3"></audio>
      <button onClick={stopSound}>Stop Sound</button>
      <button onClick={handleAddClick}>Add New Asset</button>
      {statusMessage}
      <AddAssetModal
        isOpen={isPopupOpen}
        onRequestClose={() => setIsPopupOpen(false)}
      />
      <AssetDetailsModal
        asset={selectedAsset}
        isOpen={isAssetModalOpen}
        onRequestClose={() => setIsAssetModalOpen(false)}
        onUpdateAsset={updateAsset}
      />
     <ChartModal 
        isOpen={chartModalOpen} 
        onRequestClose={() => setChartModalOpen(false)} 
        symbol={selectedSymbol} 
      />
      <div className="App">
        <table class="top">
          <tbody>
            <tr>              
               <td><div><TradeLog /></div></td>
               <td><div><TodaysForex /></div></td>
            </tr>
          </tbody>
        </table>
        <div></div>
        {/* ... other elements ... */}
      </div>
    </div>
  );
}

export default App;
